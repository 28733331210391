import { DefaultPersonForm } from '../../default-person-form/entities/default-person-form.entity';

export class RecoverProposal {
  public orders: Orders[];

  public person: DefaultPersonForm;

  constructor(data: any = {}) {
    this.orders = data.orders || [];
    this.person = data.person || new DefaultPersonForm();
  }
}

interface Orders {
  enterprise: string;
  id: string;
  status: string;
  unit: string;
}
