import { Utils } from '@/services/utils/utils.service';
import store from '@/store';
import clientService from '@/modules/Clients/services/client.service';
import { Substatus } from '@/modules/Administrative/modules/Substatus/entities/substatus.entity';
import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import { Checklist } from '@/modules/Administrative/modules/Checklists/entities/checklist.entity';
import { DefaultPersonForm } from '../../default-person-form/entities/default-person-form.entity';
import { ClientFormInterface } from '../interfaces/client-form.interface';
import { StatusEnum } from '../enums/status.enum';
import { ProductInterest } from '../../../../Administrative/modules/ProductInterests/entities/product-interest.entity';
import { Channel } from './channel.entity';
import { Timeline } from './timeline.entity';
import Attachment from '../../../../shared/entities/attachment.entity';
import { ClientPaymentCondition } from './client-payment-condition.entity';
import { Reason } from '../../../../Administrative/modules/Reasons/entities/reason.entity';
import { ClientChildren } from './client-children.entity';
import { ClientBankReferences } from './client-bank-references.entity';
import { ClientVehicles } from './client-vehicles.entity';
import { ClientHouseInfo } from './client-house-info';
import { ClientFinancingSimulation } from './client-financing-simulation.entity';
import { AttachmentEnum } from '../enums/attachment.enum';

import { ClientTypeNameEnum } from '../enums/client-type.enum';
import { ProcessRealized } from './process-realized.entity';

export class ClientForm implements ClientFormInterface {
  public id: string = '';

  public channel: Channel = new Channel();

  public product_interests: string[];

  public checklists: string[];

  public attachments: Attachment[] = [];

  public persons: DefaultPersonForm[];

  public status: Status = new Status();

  public statusBeforeSaved: StatusEnum = StatusEnum.INITIAL_CONTACT;

  public sub_statuses: any;

  public timeline: Timeline[];

  public company_id: string;

  public unit_sale_value: string;

  public selling_point_id: string;

  public enterprise_id: string;

  public unit: Unit;

  public reasons: Reason = new Reason();

  public notes: string = '';

  public ranking: number = 0;

  public payment_conditions: ClientPaymentCondition[] = [];

  public banking_references: ClientBankReferences[] = [];

  public children: ClientChildren[] = [];

  public vehicle_qty: ClientVehicles = new ClientVehicles();

  public house_info: ClientHouseInfo = new ClientHouseInfo();

  public created_by: any;

  public updated_by: any;

  public financing_simulation: ClientFinancingSimulation = new ClientFinancingSimulation();

  constructor(data: any = {}) {
    this.id = data.id;
    this.attachments = data.attachments || [];
    this.channel = new Channel(data.channel || {});
    this.company_id = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.selling_point_id = data.selling_point ? data.selling_point.id : undefined;
    this.enterprise_id = data.enterprise ? data.enterprise.id : undefined;
    this.unit = data.unit ? new Unit(data.unit) : new Unit();
    this.status = new Status(data.status);
    this.statusBeforeSaved = data.status;
    this.sub_statuses = this.formatSubstatusIds(data.sub_statuses);
    this.reasons = new Reason(data.reasons || {});
    this.product_interests = this.formatProductInterests(data.product_interests);
    this.checklists = this.formatChecklists(data.checklists);
    this.persons = this.formatPersons(data.persons);
    this.payment_conditions = this.formatPaymentCondition(data.payment_conditions, data.id);
    this.children = this.formatChildren(data.children);
    this.banking_references = this.formatBankingReferences(data.banking_references);
    this.vehicle_qty = new ClientVehicles(data.vehicle_qty);
    this.house_info = new ClientHouseInfo(data.house_info);
    this.financing_simulation = new ClientFinancingSimulation(data.financing_simulation);
    this.notes = data.notes || '';
    this.ranking = data.ranking || 0;
    this.timeline = this.formatTimeline(data.events, new Reason(data.reasons ? data.reasons : {}));
    this.unit_sale_value = data.unit_sale_value;
    this.created_by = data.created_by;
    this.updated_by = data.updated_by;
  }

  public get contractFile() {
    const contract = this.attachments.find((it: Attachment) => it.type === AttachmentEnum.CONTRACT);
    if (!contract) {
      return '';
    }
    return contract.file ? contract.file : contract.path;
  }

  public get contractFilePdf() {
    const contract = this.attachments.find((it: Attachment) => it.type === AttachmentEnum.CONTRACT);
    if (!contract) {
      return '';
    }
    return contract.path ? contract.path : '';
  }

  public get proposalFile() {
    const file = this.attachments.filter((it: Attachment) => it.type === AttachmentEnum.PROPOSAL);
    return file.length ? file[file.length - 1].path : undefined;
  }

  public get distractFile() {
    const file = this.attachments.filter((it: Attachment) => it.type === AttachmentEnum.DISTRACT);
    return file.length ? file[file.length - 1].path : undefined;
  }

  public getProcessRealized() {
    return new ProcessRealized(this).allItems();
  }

  public async setContractFile(contractFile: any) {
    const formData = new FormData();
    formData.append('file', contractFile);
    return clientService.setContract(this.id, formData);
  }

  public async setGeneralAttachment(contractFile: any) {
    const formData = new FormData();
    formData.append('file', contractFile);
    return clientService.setGeneralAttachment(this.id, formData);
  }

  public addNewPerson() {
    this.persons.push(new DefaultPersonForm({ temporaryId: Utils.generateID() }));
  }

  public removePerson(temporaryId: string): void {
    const index = this.persons.findIndex(it => it.temporaryId === temporaryId);
    if (index >= 0) {
      this.persons.splice(index, 1);
    }
  }

  public addProductInterest(product: ProductInterest) {
    this.product_interests.push(product.id);
  }

  public removeProductInterest(product: ProductInterest) {
    const index = this.product_interests.findIndex((it: string) => it === product.id);
    if (index >= 0) {
      this.product_interests.splice(index, 1);
    }
  }

  public addChecklist(checklist: Checklist) {
    this.checklists.push(checklist.id);
  }

  public removeChecklist(checklist: Checklist) {
    const index = this.checklists.findIndex((it: string) => it === checklist.id);
    if (index >= 0) {
      this.checklists.splice(index, 1);
    }
  }

  public handlePaymentCondition(paymentCondition: ClientPaymentCondition) {
    const index = this.payment_conditions.findIndex(
      (it: ClientPaymentCondition) => it.id === paymentCondition.id,
    );
    if (index >= 0) {
      this.removePaymentCondition(index);
      return;
    }
    this.addPaymentCondition(paymentCondition);
  }

  public addPaymentCondition(paymentCondition: ClientPaymentCondition) {
    this.payment_conditions.push(paymentCondition);
  }

  public removePaymentCondition(paymentConditionIndex: number) {
    this.payment_conditions.splice(paymentConditionIndex, 1);
  }

  public async create() {
    const persons = this.persons.map((person: DefaultPersonForm, index: number) => person.formatToSave(index));
    return clientService.createClient({
      ...this,
      channel_id: this.channel.id || undefined,
      company_id: this.company_id || undefined,
      status_id: this.status.id,
      selling_point_id: this.selling_point_id || undefined,
      enterprise_id: this.enterprise_id || undefined,
      unit_id: this.unit.id || undefined,
      persons,
    });
  }

  public async update() {
    const persons = this.persons.map((person: DefaultPersonForm, index) => person.formatToSave(index));
    return clientService.updateOrderById({
      ...this,
      channel_id: this.channel.id || undefined,
      company_id: this.company_id || undefined,
      status_id: this.status.id,
      created_by: this.created_by.id,
      selling_point_id: this.selling_point_id || undefined,
      enterprise_id: this.enterprise_id || undefined,
      unit_id: this.unit.id || undefined,
      payment_conditions: this.formatPaymentConditionsToSave(),
      children: this.children.length ? this.children : undefined,
      banking_references: this.banking_references.length ? this.banking_references : undefined,
      house_info: this.house_info.rent_own ? this.house_info : undefined,
      persons,
    });
  }

  private formatSubstatusIds(substatus: Substatus[] = []) {
    return substatus.map((it: Substatus) => it.id);
  }

  private formatPaymentConditionsToSave() {
    return this.payment_conditions.length
      ? this.payment_conditions.map((payment: ClientPaymentCondition) => payment.formatToSend())
      : undefined;
  }

  private formatProductInterests(data: Array<ProductInterest> = []) {
    return data && data.length ? data.map((it: ProductInterest) => it.id) : [];
  }

  private formatChecklists(data: Array<Checklist> = []) {
    return data && data.length ? data.map((it: Checklist) => it.id) : [];
  }

  private formatPaymentCondition(data: Array<ClientPaymentCondition> = [], order_id: string) {
    return data && data.length
      ? data.map((it: ClientPaymentCondition) => new ClientPaymentCondition({ ...it, order_id }))
      : [];
  }

  private formatTimeline(events: any[], reasons: Reason) {
    return events && events.length ? events.map((it: any) => new Timeline(it, reasons)) : [];
  }

  private formatPersons(data: DefaultPersonForm[] = []) {
    return data && data.length
      ? data
        .map((it: any) => new DefaultPersonForm(it))
        .sort((a: DefaultPersonForm, b: DefaultPersonForm) => (a.order_number > b.order_number ? 1 : -1))
      : [new DefaultPersonForm()];
  }

  private formatChildren(data: ClientChildren[] = []) {
    return data && data.length ? data.map((it: any) => new ClientChildren(it)) : [];
  }

  private formatBankingReferences(data: ClientBankReferences[] = []) {
    return data && data.length ? data.map((it: any) => new ClientBankReferences(it)) : [];
  }
}
