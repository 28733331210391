import { Utils } from '@/services/utils/utils.service';

export class ClientBankReferences {
  public id: string;

  public name: string;

  public branch: string;

  public account: string;

  constructor(data: any = {}) {
    this.id = Utils.generateID();
    this.name = data.name || '';
    this.branch = data.branch || '';
    this.account = data.account || '';
  }
}
