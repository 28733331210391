
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../entities/client-form.entity';
import UploadComponent from '../../../../../../components/upload/upload.component.vue';
import rulesService from '../../../../../../services/rules.service';
import Attachment from '../../../../../shared/entities/attachment.entity';
import downloadService from '../../../../../../services/download.service';
import clientService from '../../../../services/client.service';
import { AttachmentEnum } from '../../enums/attachment.enum';

@Component({ components: { UploadComponent } })
export default class ClientFormContractDialog extends Vue {
  public $refs: any;

  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  private readonly client!: ClientForm;

  private rules = {
    required: rulesService.required,
    isPdf: rulesService.isPdfFile,
  };

  private fileToSend: any = '';

  private dialog: boolean = false;

  private get labelButton() {
    return this.client.contractFile ? 'Editar contrato' : 'Enviar contrato';
  }

  private get showBtn() {
    const file = this.client.attachments.filter(
      (it: Attachment) => it.type === AttachmentEnum.PROPOSAL,
    );

    return file.length > 0;
  }

  private downloadContract() {
    if (this.client.contractFilePdf) {
      downloadService.openInNewTab(this.client.contractFilePdf);
      return;
    }
    downloadService.downloadFile(
      new Attachment({
        file: this.client.contractFile,
        name: 'Contrato',
      }),
    );
  }

  private async sendContract() {
    if (!this.$refs.form.validate()) return;
    await this.client.setContractFile(this.fileToSend[0]);
    this.$emit('send-contract');
    this.dialog = false;
  }
}
