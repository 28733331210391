
import { Component, Vue, Prop } from 'vue-property-decorator';
import Attachment from '../../../../../shared/entities/attachment.entity';
import rulesService from '../../../../../../services/rules.service';
import UploadComponent from '../../../../../../components/upload/upload.component.vue';
import { Utils } from '../../../../../../services/utils/utils.service';

@Component({
  components: {
    UploadComponent,
  },
})
export default class ClientFormAttachmentDialogComponent extends Vue {
  public $refs: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  private attachment: any = { file: [] };

  private rules: any = {
    required: rulesService.required,
  };

  get filename() {
    return this.attachment.file && this.attachment.file.length
      ? this.attachment.file[0].name
      : 'Anexo';
  }

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private save() {
    if (!this.$refs.form.validate()) return;
    this.$emit('save', this.attachment);
  }
}
