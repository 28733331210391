
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Reason } from '../../../../../Administrative/modules/Reasons/entities/reason.entity';
import reasonService from '../../../../../Administrative/modules/Reasons/services/reason.service';
import rulesService from '../../../../../../services/rules.service';
import { ClientForm } from '../../entities/client-form.entity';
import { ClientGiveUp } from '../../entities/client-give-up.entity';
import { StatusEnum } from '../../enums/status.enum';

@Component
export default class ClientGiveUpDialog extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private reasons: Reason[] = [];

  private clientGiveUp: ClientGiveUp = new ClientGiveUp();

  private rules = {
    required: rulesService.required,
  };

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('close');
  }

  private giveUp() {
    if (!this.$refs.form.validate()) return;
    const { id } = this.$route.params;

    this.clientGiveUp
      .save(id)
      .then(() => {
        this.$snackbar.open({
          text: 'Processo de desistência realizado',
          color: 'success',
          buttonColor: 'white',
        });
        this.$emit('send-give-up');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao gerar a desistência',
          color: 'dange',
          buttonColor: 'white',
        });
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  private async getReasons() {
    const companyId = this.clientForm.company_id;
    const reasons: any[] = await reasonService.getReasonByType(
      this.clientForm.company_id,
      StatusEnum.GIVE_UP,
    );
    this.reasons = reasons.map((it: any) => new Reason(it));
  }

  private created() {
    this.getReasons();
  }
}
