
import { Component, Vue, Prop } from 'vue-property-decorator';
import RadioButtonCustom from '@/components/radio-button-custom/radio-button-custom.component.vue';
import clientService from '@/modules/Clients/services/client.service';
import { ClientForm } from '../../entities/client-form.entity';
import { Channel } from '../../entities/channel.entity';

@Component({ components: { RadioButtonCustom } })
export default class ClientFormChannel extends Vue {
  @Prop({
    type: String,
    default: () => '',
  })
  private readonly value!: string;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private channels: Channel[] = [];

  private showOtherChannels: boolean = false;

  private showMenu: boolean = false;

  private mainChannels: Channel[] = [];

  private itemsMenuChannels: string[] = ['Adicionar', 'Ordenar mais usados'];

  private setChannel(channel: any) {
    if (this.value === channel.id) {
      this.$emit('input', '');
      return;
    }
    if (channel.description === 'Outros') {
      this.showOtherChannels = true;
      this.$emit('input', '');
      return;
    }
    this.showOtherChannels = false;
    this.$emit('input', channel.id);
  }

  private setOtherChannel(channelId: string) {
    this.$emit('input', channelId);
  }

  private async getChannels() {
    const mainChannels = await clientService.getMainChannels(this.clientForm.company_id);
    this.mainChannels = mainChannels.map((channel: Channel) => new Channel(channel));

    const channels = await clientService.getChannels(this.clientForm.company_id);
    this.channels = channels.map((channel: Channel) => new Channel(channel));
  }

  private created() {
    this.getChannels();
  }
}
