import moment from '@/services/moment/moment.service';
import {
  TimelineColors,
  TimelineIcons,
  TimelineLabel,
  TimelineValues,
} from '../enums/timeline.enum';
import { Reason } from '../../../../Administrative/modules/Reasons/entities/reason.entity';

export class Timeline {
  public id: string = '';

  public type: string = '';

  public value: string = '';

  public reasons: Reason = new Reason();

  public created_at: string = '';

  public order_status_id: string = '';

  public created_by: string = '';

  constructor(data: any = {}, reasons: any) {
    this.id = data.id;
    this.reasons = reasons || new Reason();
    this.type = data.column;
    this.value = data.value;
    this.order_status_id = data.order_status_id;
    this.created_by = data.created_by ? data.created_by.name : 'Usuário não encontrado';
    this.created_at = data.created_at;
  }

  public get isGiveUpOrDistract() {
    return this.value === TimelineValues.DISTRACT || this.value === TimelineValues.GIVE_UP;
  }

  public get isChangedStatus() {
    return this.type === 'changed_status';
  }

  public get description() {
    if (this.isChangedStatus) {
      return this.value;
    }
    if (this.isGiveUpOrDistract) {
      return `${this.reasons.description} - ${this.reasons.justification} ${'<br>'}
      Esse registro foi gerado por ${this.created_by} no dia ${this.createdAtFormatted}`;
    }
    return `Esse registro foi gerado por ${this.created_by} no dia ${this.createdAtFormatted}`;
  }

  public get createdAtFormatted() {
    return moment(this.created_at).format('DD/MM/YYYY HH:mm');
  }

  public get color() {
    if (this.isGiveUpOrDistract) {
      return 'danger';
    }
    const colors: any = TimelineColors;
    return colors[this.type];
  }

  public get icon() {
    if (this.isGiveUpOrDistract) {
      return 'close';
    }
    const icons: any = TimelineIcons;
    return icons[this.type];
  }

  public get title() {
    if (this.isChangedStatus) {
      return 'Alteração de data do status';
    }
    const label: any = TimelineLabel;
    return `${label[this.type]} - ${this.value}`;
  }
}
