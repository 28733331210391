
import { Component, Vue, Prop } from 'vue-property-decorator';

import attachmentService from '@/modules/shared/services/attachment.service';
import { ClientForm } from '../../entities/client-form.entity';
import Attachment from '../../../../../shared/entities/attachment.entity';
import { Utils } from '../../../../../../services/utils/utils.service';
import ClientFormAttachmentDialog from './client-form-attachments.dialog.component.vue';
import downloadService from '../../../../../../services/download.service';

@Component({
  components: {
    ClientFormAttachmentDialog,
  },
})
export default class ClientFormAttachments extends Vue {
  private attachments: any = [];

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly client!: ClientForm;

  private showDialog: boolean = false;

  private headers: any = [
    { text: 'Nome', value: 'name', width: '90%' },

    {
      text: 'Ações',
      align: 'center',
      value: 'actions',
    },
  ];

  private get generalAttachments() {
    return this.client.attachments.filter((it: Attachment) => it.type === 'GENERAL');
  }

  private openDialog() {
    this.showDialog = true;
  }

  private closeDialog() {
    this.showDialog = false;
  }

  private async save(attachment: Attachment) {
    this.$loader.open();
    await this.client
      .setGeneralAttachment(attachment.file[0])
      .then(() => {
        this.$snackbar.open({
          text: 'Anexo adicionado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('add-attachment');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao adicionar o anexo',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  private openAttachment(attachment: Attachment) {
    if (attachment.path) {
      downloadService.openInNewTab(attachment.path);
      return;
    }
    downloadService.downloadFile(attachment);
  }

  private async deleteAttachmment(attachment: Attachment) {
    this.$loader.open();
    await attachmentService
      .removeAttachmentById(attachment.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Anexo removido com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('add-attachment');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao remover o anexo',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialog();
      });
  }
}
