import moment from '@/services/moment/moment.service';
import store from '@/store';

import { Address } from '@/components/address/entities/address.entity';

import { Utils } from '@/services/utils/utils.service';
import { DefaultPersonFormInterface } from '../interfaces/default-person-form.interface';
import { ClientTypeNameEnum } from '../../form/enums/client-type.enum';

export class DefaultPersonForm implements DefaultPersonFormInterface {
  public id: string = '';

  public order_number: number = 0;

  public temporaryId: string = '';

  public name: string = '';

  public cellphone: string = '';

  public phone: string = '';

  public marital_status: string = '';

  public gender: string = '';

  public born_city: any = '';

  public email: string = '';

  public active: number;

  public profession: string;

  public nationality: string;

  public company_id: string;

  public birth_date: string;

  public company: string;

  public cpf: string;

  public rg: string;

  public average_wage: string;

  public fgts_value: string;

  public potential_purchase_value: string;

  public months_registered: number;

  public status: string;

  public type: ClientTypeNameEnum;

  public address: Address = new Address();

  public created_by: UserCreatedBy = { id: '', name: '' };

  constructor(data: any = {}) {
    this.id = data.id;
    this.temporaryId = data.temporaryId || Utils.generateID();
    this.name = data.name || '';
    this.cellphone = this.formatCellphoneMask(data.cellphone);
    this.phone = this.formatPhoneMask(data.phone);
    this.marital_status = data.marital_status || '';
    this.gender = data.gender || '';
    this.born_city = this.getBornCity(data);
    this.email = data.email || '';
    this.type = data.type || '';
    this.company = data.company || '';
    this.active = data.active;
    this.profession = data.profession;
    this.birth_date = data.birth_date;
    this.nationality = data.nationality;
    this.company_id = data.company_id || store.getters.user.company_id;
    this.cpf = data.cpf || '';
    this.rg = data.rg || '';
    this.order_number = data.order_number || 0;
    this.status = data.status;
    this.average_wage = data.average_wage || '';
    this.created_by = data.created_by || { id: '', name: '' };
    this.fgts_value = data.fgts_value || '';
    this.potential_purchase_value = data.potential_purchase_value || '';
    this.months_registered = data.months_registered;
    this.address = this.getAddress(data);
  }

  public formatToSave(order_number: number) {
    return {
      ...this,
      order_number,
      type: this.type ? this.type : ClientTypeNameEnum.PROPONENT,
      cpf: this.cpf ? this.removeCharactersText(this.cpf) : undefined,
      cellphone: this.removeCharactersText(this.cellphone),
      phone: this.removeCharactersText(this.phone),
      average_wage: this.average_wage,
      fgts_value: this.fgts_value,
      potential_purchase_value: this.potential_purchase_value,
      rg: this.removeCharactersText(this.rg),
      born_city_id: this.born_city.city.id || undefined,
      birth_date: this.birth_date ? moment(this.birth_date).format('YYYY-MM-DD') : null,
      address: this.address.address ? this.address.formatToSave() : undefined,
    };
  }

  public removeCharactersText(text: string = '') {
    const value = String(text);
    return value ? value.replace(/[^0-9]+/g, '') : undefined;
  }

  public currencyFormat(text: string = '') {
    const value = String(text);
    return value
      ? value
        .replace('R$', '')
        .replace(/\./g, '')
        .replace(',', '.')
      : undefined;
  }

  private formatCellphoneMask(phone: string) {
    return phone ? String(phone).replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3') : '';
  }

  private formatPhoneMask(phone: string) {
    return phone ? String(phone).replace(/^(\d\d)(\d{4})(\d{4}).*/, '($1) $2-$3') : '';
  }

  private getBornCity(data: any = {}) {
    if (!data || (data.born_city && data.born_city.id)) {
      return new Address({
        city: {
          id: data.born_city.id,
          state: data.born_city.state,
        },
      });
    }
    return new Address();
  }

  private getAddress(data: any = {}) {
    if (data.address) {
      return new Address(data.address);
    }
    return new Address();
  }
}

type UserCreatedBy = {
  id: string;
  name: string;
};
