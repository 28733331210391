
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';
import DatePicker from '../../../../../../components/date-picker/date-picker.component.vue';
import { ClientChildren } from '../../entities/client-children.entity';

@Component({
  components: {
    DatePicker,
  },
})
export default class ClientChildrenDialog extends Vue {
  private child: ClientChildren = new ClientChildren({});

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  @Prop({ type: Object, default: () => new ClientChildren({}) })
  private readonly childToEdit!: ClientChildren;

  @Prop({ type: Boolean, default: () => false })
  private readonly edit!: boolean;

  private get buttonLabel() {
    return this.edit ? 'Editar filho' : 'Adicionar filho';
  }

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private addChild() {
    this.$emit('save', this.child);

    this.child = new ClientChildren({});
    this.closeDialog();
  }

  private created() {
    if (this.edit) {
      this.child = this.childToEdit;
    }
  }
}
