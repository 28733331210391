
import { Vue, Prop, Component } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';
import rulesService from '../../../../../../services/rules.service';

@Component
export default class ClientVehiclesDataComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private rules = {
    greaterThanOrEqualZero: rulesService.greaterThanOrEqualZero,
  };
}
