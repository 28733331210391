
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../entities/client-form.entity';

import clientService from '../../../services/client.service';
import enterpriseService from '../../../../Administrative/modules/Enterprises/services/enterprise.service';
import ClientFormRegister from './client-form-register.component.vue';
import { DefaultPersonForm } from '../../default-person-form/entities/default-person-form.entity';

@Component({ components: { ClientFormRegister } })
export default class ClientFirstContactComponent extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private show = 0;

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get companyId() {
    return this.clientForm.company_id;
  }

  private get clientName() {
    return this.clientForm.persons.length && this.clientForm.persons[0].name
      ? `- ${this.clientForm.persons[0].name}`
      : '';
  }

  private addNewPerson() {
    this.clientForm.addNewPerson();
  }

  private deleteClient(temporaryId: string) {
    this.clientForm.removePerson(temporaryId);
  }

  private setNotifyUserThatRecoverData() {
    this.$emit('set-notify-user-that-recover-data');
  }

  private validate(): boolean {
    return this.$refs.form.every((it: any) => it.validate());
  }

  private findIndexClientById(id: string) {
    return this.clientForm.persons.findIndex(
      (person: DefaultPersonForm) => person.id === id || person.temporaryId === id,
    );
  }

  private updateClientData(newPersonData: DefaultPersonForm, clientToChange: DefaultPersonForm) {
    if (newPersonData) {
      const id = clientToChange.id || clientToChange.temporaryId;
      const index = this.findIndexClientById(id);
      this.clientForm.persons.splice(index, 1, new DefaultPersonForm({ ...newPersonData, id: '' }));
    }
  }
}
