
import { Component, Vue, Prop } from 'vue-property-decorator';

import AlertDialog from '@/components/dialogs/alert-dialog.component .vue';

@Component({ components: { AlertDialog } })
export default class ClientSendProposalDialog extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private closeDialog(data: boolean = false) {
    if (data) return;
    this.$emit('close');
  }

  private sendProposal() {
    this.$emit('send-proposal');

    this.closeDialog();
  }
}
