import Attachment from '../../../../shared/entities/attachment.entity';
import { AttachmentEnum } from '../enums/attachment.enum';
import { ClientForm } from './client-form.entity';

export class ProcessRealized {
  private client: ClientForm = new ClientForm();

  constructor(data: any = {}) {
    this.client = data || new ClientForm();
  }

  private get contractLabel() {
    if (this.client.contractFilePdf) {
      return 'Contrato enviado';
    }
    return 'Contrato anexado';
  }

  private get distractLabel() {
    if (this.client.contractFilePdf) {
      return 'Distrato enviado';
    }
    return 'Distrato anexado';
  }

  private get processTypes() {
    return {
      PROPOSAL: {
        type: 'PROPOSAL',
        icon: 'assessment',
        text: 'Proposta enviada',
        color: 'success',
        class: 'client-form-notification__proposal',
        info: 'Fazer download da proposta',
      },
      UNIT_RESERVED: {
        type: 'UNIT_RESERVED',
        icon: 'apartment',
        text: 'Unidade reservada',
        color: 'success',
        class: 'client-form-notification__reserved',
        info: 'Visualizar unidade reservada',
      },
      CONTRACT_SENDED: {
        type: 'CONTRACT_SENDED',
        icon: 'description',
        text: this.contractLabel,
        color: 'primary',
        class: 'client-form-notification__contract',
        info: 'Fazer download do contrato',
      },
      DISTRACT_SENDED: {
        type: 'DISTRACT_SENDED',
        icon: 'description',
        text: this.distractLabel,
        color: 'primary',
        class: 'client-form-notification__contract',
        info: 'Fazer download do distrato',
      },
    };
  }

  public allItems() {
    const allItems: any[] = [];
    if (this.client.unit.reserve && this.client.unit.reserve.id) {
      allItems.push(this.processTypes.UNIT_RESERVED);
    }
    if (this.client.attachments.find((it: Attachment) => it.type === AttachmentEnum.PROPOSAL)) {
      allItems.push(this.processTypes.PROPOSAL);
    }
    if (this.client.attachments.find((it: Attachment) => it.type === AttachmentEnum.CONTRACT)) {
      allItems.push(this.processTypes.CONTRACT_SENDED);
    }
    if (this.client.attachments.find((it: Attachment) => it.type === AttachmentEnum.DISTRACT)) {
      allItems.push(this.processTypes.DISTRACT_SENDED);
    }
    return allItems;
  }
}
