export class ClientVehicles {
  public cars: number;

  public others: number;

  public motorcycle: number;

  constructor(data: any = {}) {
    this.cars = data ? data.cars : 0;
    this.others = data ? data.others : 0;
    this.motorcycle = data ? data.motorcycle : 0;
  }
}
