
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RecoverProposal } from '../../entities/recover-proposal.entity';

@Component({
  components: {},
})
export default class RecoverProposalDialog extends Vue {
  public $refs: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  @Prop({ type: Boolean, default: () => true })
  private readonly currentUserisOwner!: boolean;

  @Prop({ type: Object, default: () => new RecoverProposal() })
  private readonly proposalToRecover!: RecoverProposal;

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private get userOwnnerName() {
    return this.proposalToRecover.person.created_by.name;
  }

  private goToProposal(id: string) {
    this.$emit('go-to-proposal', id);
  }

  private updateClient() {
    this.$emit('update-client');
  }
}
