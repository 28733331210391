
import { Component, Vue, Watch } from 'vue-property-decorator';

import { ClientForm } from './entities/client-form.entity';
import clientService from '../../services/client.service';
import AlertError from '../../../../components/alert-error/alert-error.component.vue';
import ClientReminders from '../reminders/client-reminders.component.vue';
import orderService from '../../../Administrative/modules/Orders/services/order.service';
import { StatusEnum } from './enums/status.enum';
import ClientFormWrapper from './components/client-form-wrapper.component.vue';
import ClientFormTimeline from './components/timeline/client-form-timeline.component.vue';
import ClientFormComments from './components/comments/client-form-comments.component.vue';
import ClientFormAttachments from './components/attachments/client-form-attachments.component.vue';
import ownerNotifcation from '../../services/owner-notifcation';
import ClientFormTasks from '../tasks/client-form-tasks.vue';
import SimulationProposal from '../simulation-proposal/SimulationProposal.vue';
import VisitSchedule from '../visit-schedule/VisitSchedule.vue';

@Component({
  components: {
    ClientFormWrapper,
    ClientFormTimeline,
    ClientFormComments,
    ClientFormAttachments,
    ClientReminders,
    AlertError,
    ClientFormTasks,
    SimulationProposal,
    VisitSchedule,
  },
})
export default class ClientFormComponent extends Vue {
  private tab: any = null;

  public $refs: any;

  private client: ClientForm = new ClientForm();

  private loadingClientForm: boolean = false;

  public notifyUserThatRecoverData: boolean = false;

  private errors: any = {};

  public get userCreated() {
    return this.client.created_by ? this.client.created_by.name : '';
  }

  public get userUpdated() {
    return this.client.updated_by ? this.client.updated_by.name : '';
  }

  private get items(): any[] {
    return [
      { id: 'proposal-data', tab: 'Dados da proposta', component: ClientFormWrapper },
      {
        id: 'attachments',
        tab: 'Anexos Gerais',
        component: ClientFormAttachments,
        disabled: !this.client.id,
      },
      {
        id: 'timeline',
        tab: 'Timeline',
        component: ClientFormTimeline,
        disabled: !this.client.id,
      },
      {
        id: 'comments',
        tab: 'Comentários',
        component: ClientFormComments,
        disabled: !this.client.id,
      },
      {
        id: 'reminder',
        tab: 'Lembretes',
        component: ClientReminders,
        disabled: !this.client.id,
      },
      {
        id: 'tasks',
        tab: 'Tarefas',
        component: ClientFormTasks,
        disabled: !this.client.id,
      },
      {
        id: 'simulation-proposal',
        tab: 'Simulação de proposta',
        component: SimulationProposal,
        disabled: !this.client.id,
      },
      {
        id: 'visit-schedule',
        tab: 'Agendamento de visitas',
        component: VisitSchedule,
        disabled: !this.client.id,
      },
    ];
  }

  private get isEdit() {
    if (this.client.id) {
      const { id } = this.client;
      return !!id;
    }
    return false;
  }

  private get showSaveButton() {
    const statusNotAllowed = [StatusEnum.DISTRACT, StatusEnum.GIVE_UP, StatusEnum.CLOSED];
    return !statusNotAllowed.some((status: string) => status === this.client.statusBeforeSaved);
  }

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private async goToList() {
    this.$router.push({
      name: 'client-list',
    });
  }

  private setNotifyUserThatRecoverData() {
    this.notifyUserThatRecoverData = true;
  }

  private async refreshData() {
    this.$loader.open();
    await this.updateClient();
    await this.getInformation();
    this.$loader.close();
  }

  private async sendProposal() {
    if (!this.validateAllForms()) {
      this.$snackbar.open({
        text: 'Alguns campos obrigatórios não foram preenchidos',
        color: 'danger',
        buttonColor: 'white',
      });
      return;
    }
    this.errors = {};
    this.$loader.open();
    await this.updateClient();
    orderService
      .sendProposal(this.client.id)
      .then(async () => {
        this.$snackbar.open({
          text: 'Proposta enviada com sucesso',
          color: 'success',
          buttonColor: 'white',
        });
        await this.getInformation();
        this.$loader.close();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao enviar a proposta',
          color: 'danger',
          buttonColor: 'white',
        });

        this.errors = this.getErrorsOnRequest(err);

        this.scrollTop();

        this.$loader.close();
      });
  }

  private getErrorsOnRequest(err: any) {
    if (err.response && err.response.data.errors) {
      return err.response.data.errors;
    }
    if (err.response && err.response.data.message) {
      return {
        'Ocorreu um erro': [err.response.data.message],
      };
    }
    return {
      'Erro Desconhecido': ['Ocorreu um erro eo enviar a proposta'],
    };
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private onChangeTab(item: any) {
    const tab = this.items[item];
    this.$router.push({
      name: this.$route.name || '',
      params: {
        tab: tab.id,
      },
    });
  }

  private async getInformation(clientId?: string) {
    const id = clientId || this.$route.params.id;
    if (id) {
      this.loadingClientForm = false;
      const client = await clientService
        .getClientById(id)
        .catch(() => {
          this.goToList();
          this.$snackbar.open({
            text: 'Ocorreu um erro ao buscar os dados desse usuário',
            color: 'danger',
            buttonColor: 'white',
          });
        })
        .finally(() => {
          this.$loader.close();
        });
      this.client = new ClientForm(client);
      this.loadingClientForm = true;
    }
    this.loadingClientForm = true;
  }

  private validateAllForms() {
    return this.$refs.form.every((it: any) => (it.validate ? it.validate() : true));
  }

  private async save(e: Event) {
    e.preventDefault();
    if (!this.validateAllForms()) {
      this.$snackbar.open({
        text: 'Alguns campos obrigatórios não foram preenchidos',
        color: 'danger',
        buttonColor: 'white',
      });
      return;
    }
    this.errors = {};
    this.$loader.open();
    if (this.isEdit) {
      await this.updateClient();
      await this.getInformation();
      this.$loader.close();
      return;
    }
    await this.createClient();
    this.$loader.close();
  }

  private createNotificationToOwnerClient() {
    const user = this.client.persons.find(it => !!it.cellphone);
    if (user) {
      const { cellphone } = user.formatToSave(1);
      ownerNotifcation
        .notify({ cellphone, company_id: this.client.company_id })
        .catch((err: any) => {
          throw new Error(`${err} Erro ao enviar a notificação para o criador desse cliente`);
        });
    }
  }

  private async createClient() {
    if (this.notifyUserThatRecoverData) {
      await this.createNotificationToOwnerClient();
    }
    return this.client
      .create()
      .then(async (data: any) => {
        this.$snackbar.open({
          text: 'Cliente cadastrado com sucesso',
          color: 'success',
          buttonColor: 'white',
        });
        await this.goToFormEdit(data.id);
        await this.getInformation(data.id);
      })
      .catch((err: any) => {
        this.errors = this.getErrorsOnRequest(err);
        this.scrollTop();
      });
  }

  private goToFormEdit(clientId: string) {
    this.$router.push({
      name: 'client-edit',
      params: {
        id: clientId,
      },
    });
  }

  private async updateClient() {
    return this.client
      .update()
      .then(() => {
        this.$snackbar.open({
          text: 'Os dados do cliente foram atualizados.',
          color: 'success',
          buttonColor: 'white',
        });
      })
      .catch((err: any) => {
        this.errors = this.getErrorsOnRequest(err);
        this.scrollTop();
      });
  }

  @Watch('$route')
  private goToTab() {
    const { tab } = this.$route.params;
    if (tab) {
      const index = this.items.findIndex((it: any) => it.id === tab);
      this.tab = index >= 0 ? index : 0;
    }
  }

  private async created() {
    this.$loader.open();
    await this.getInformation();
    await this.goToTab();
    this.$loader.close();
  }
}
