import http from '@/services/http.service';

class VisitSchedulesService {
  getAllSchedulesVisits(order_id: string) {
    return http.get('/order-visit', { params: { order_id } }).then(({ data }) => data.data);
  }

  getScheduleVisitById(id: string) {
    return http.get(`/order-visit/${id}`).then(({ data }) => data.data);
  }

  createVisitSchedule(visitSchedule: any) {
    return http.post('/order-visit/', visitSchedule).then(({ data }) => data.data);
  }

  deleteSchedule(id: string) {
    return http.delete(`/order-visit/${id}`).then(({ data }) => data.data);
  }

  markAsConcluded(id: string, visited_at: string) {
    return http
      .patch(`/order-visit/${id}/visited`, {
        visited_at,
      })
      .then(({ data }) => data.data);
  }
}

export default new VisitSchedulesService();
