import { IPagination } from '@/entities/pagination/pagination.interface';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { AxiosResponse } from 'axios';
import { IEnterpriseBlocksService } from '../interface/enterprise-blocks-service.interface';

class EnterpriseBlocksService implements IEnterpriseBlocksService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  getBlocks(enterprise_id: string): Promise<any> {
    return http
      .get('/block', {
        params: {
          enterprise_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  getUnitsByBlockId(
    block_id: string,
    pagination?: IPagination,
    commercial_stock?: string,
  ): Promise<any> {
    return http
      .get('/unit/basic-group', { params: { block_id, commercial_stock, ...pagination } })
      .then(({ data }: AxiosResponse) => data);
  }

  getUnitsByBlockIdPaginated(block_id: string, commercial_stock?: string): Promise<any> {
    return http
      .get('/unit/basic-group', { params: { block_id, commercial_stock, paginate: 1 } })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }
}

const simplePaginationService = new SimplePagination();
export default new EnterpriseBlocksService(simplePaginationService);
