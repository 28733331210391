export class ClientFinancingSimulation {
  public financing_term: number;

  public amortization_system: string;

  public simulated_interest_rate: number;

  public has_three_fgts_years_old: boolean;

  public already_benefited_from_fgts: boolean;

  public simulated_value_to_last_installment: number;

  public simulated_value_to_first_installment: number;

  constructor(data: any = {}) {
    this.financing_term = data ? data.financing_term : undefined;
    this.amortization_system = data ? data.amortization_system : undefined;
    this.simulated_interest_rate = data ? data.simulated_interest_rate : undefined;
    this.has_three_fgts_years_old = data ? data.has_three_fgts_years_old : undefined;
    this.already_benefited_from_fgts = data ? data.already_benefited_from_fgts : undefined;
    this.simulated_value_to_last_installment = data
      ? data.simulated_value_to_last_installment
      : undefined;
    this.simulated_value_to_first_installment = data
      ? data.simulated_value_to_first_installment
      : undefined;
  }
}
