
import { Component, Vue, Prop } from 'vue-property-decorator';
import ListSkeleton from '@/modules/shared/components/list-skeleton.component.vue';
import { ClientForm } from '../form/entities/client-form.entity';
import SimulationProposalFormComponent from './simulation-proposal-form.component.vue';
import SimulationHistoriesComponent from './simulation-histories.component.vue';

@Component({
  components: {
    ListSkeleton,
    SimulationProposalFormComponent,
    SimulationHistoriesComponent,
  },
})
export default class SimulationProposal extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly client!: ClientForm;

  public showSimulationHistories: boolean = false;

  setShowSimulationHistories() {
    this.showSimulationHistories = true;
  }
}
