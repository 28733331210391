
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';
import ClientVehiclesData from './client-vehicles-data.component.vue';
import ClientHouseInfoData from './client-house-info-data.component.vue';
import ClientChildrenData from './client-children-data.component.vue';
import ClientBankingReferencesData from './client-banking-references-data.component.vue';
import ClientFinancingSimulationData from './client-financing-simulation-data.component.vue';
import ClientNotesComponent from './client-notes.component.vue';

@Component({
  components: {
    ClientVehiclesData,
    ClientNotesComponent,
    ClientHouseInfoData,
    ClientChildrenData,
    ClientBankingReferencesData,
    ClientFinancingSimulationData,
  },
})
export default class ClientAdditionalData extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;
}
