
import { Component, Vue, Prop } from 'vue-property-decorator';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import rulesService from '@/services/rules.service';
import { PaymentCondition } from '@/modules/Administrative/modules/PaymentConditions/entities/payment-condition.entity';
import { ClientPaymentCondition } from '../../entities/client-payment-condition.entity';

@Component({
  components: {
    DatePicker,
  },
})
export default class ClientPaymentConditionItemComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  private readonly paymentItem!: ClientPaymentCondition;

  private rules: any = {
    required: rulesService.required,
  };

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private openDialog() {
    this.$emit('open-dialog', this.paymentItem);
  }
}
