
import { mapGetters } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';
import rulesService from '@/services/rules.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import { Block } from '@/modules/Administrative/modules/Enterprises/components/form/entities/block.entity';
import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import enterpriseBlocksService from '@/modules/Administrative/modules/Enterprises/components/form/services/enterprise-blocks.service';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import TimePicker from '@/components/date-picker/time-picker.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import { Group } from '@/modules/Administrative/modules/Users/entities/group.entity';
import userGroupService from '@/modules/Administrative/modules/Users/services/user-group.service';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { GroupEnum } from '@/modules/Administrative/modules/Users/enum/group.enum';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import { VisitSchedule } from './entities/visit-schedule';
import { ReminderTo } from '../reminders/enums/reminder.enum';
import { ClientForm } from '../form/entities/client-form.entity';

@Component({
  computed: { ...mapGetters(['user']) },
  components: { TimePicker, DatePicker },
})
export default class VisitScheduleFormComponent extends Vue {
  public $refs: any;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly order_id!: string;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly scheduleToEdit!: string;

  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public client!: ClientForm;

  public company_id: string = '';

  public users: User[] = [];

  public enterprise_id: string = '';

  public companies: Company[] = [];

  public unit_id: string = '';

  public user!: any;

  public blockId: string = '';

  public rules = {
    required: rulesService.required,
    isValidHour: this.isValidHour,
  };

  public enterprises: Enterprise[] = [];

  public blocks: Block[] = [];

  public units: Unit[] = [];

  public visitScheduleForm = new VisitSchedule();

  public loadingData: boolean = false;

  public groups: any[] = [
    {
      id: ReminderTo.ME,
      name: 'Apenas para mim',
    },
    {
      id: ReminderTo.ALL,
      name: 'Criar lembrete para todos',
    },
    {
      id: ReminderTo.GROUP,
      name: 'Um grupo específico de usuários',
    },
  ];

  public get showUsersToSelect() {
    return this.visitScheduleForm.to === ReminderTo.GROUP;
  }

  public get enterprisesSorted() {
    return this.sortArrayByTextName(this.enterprises);
  }

  public get unitsSorted() {
    return this.sortArrayByOrder(this.units);
  }

  private sortArrayByOrder(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.order_number < b.order_number) {
        return -1;
      }
      if (a.order_number > b.order_number) {
        return 1;
      }
      return 0;
    });
  }

  private isValidHour(hour: string) {
    if (!this.visitScheduleForm.day) return '';

    return moment(`${this.visitScheduleForm.day} ${hour}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  private sortArrayByTextName(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  public setEnterpriseId(id: string) {
    this.enterprise_id = id;
    this.unit_id = '';
    this.getBlocks();
  }

  public setUnitId(id: string) {
    this.unit_id = id;
    this.visitScheduleForm.unit_id = id;
  }

  public async getBlocks() {
    this.blocks = await enterpriseBlocksService.getBlocks(this.enterprise_id);
  }

  public setBlockId(enterpriseId: string) {
    this.blockId = enterpriseId;
    this.units = [];
    this.getUnits();
  }

  private async getUnits() {
    if (!this.enterprise_id) return;
    const { data } = await enterpriseBlocksService.getUnitsByBlockId(this.blockId);
    this.units = data.map((it: Unit) => new Unit(it)).map(this.validateReserveId);
  }

  private validateReserveId(unit: Unit) {
    if (!unit.can_do_reserve || (unit.reserve && unit.reserve.id)) {
      return {
        ...unit,
        name: `${unit.name} - Já reservado`,
        disabled: true,
      };
    }
    return unit;
  }

  public allowedMinutes(minutes: string) {
    const today = moment().format('YYYY-MM-DD');
    const day = moment(this.visitScheduleForm.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const hour = this.visitScheduleForm.hour
      ? moment(this.visitScheduleForm.hour, 'HH:mm').format('HH')
      : '00';
    const isHourValid = this.allowedHours(hour);
    if (!isHourValid) {
      return true;
    }

    return moment(`${day} ${hour}:${minutes}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  public allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  public allowedHours(hour: string) {
    const day = moment(this.visitScheduleForm.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const minutes = moment(this.visitScheduleForm.hour, 'HH:mm').format('mm');

    return moment(`${day} ${hour}`, 'YYYY-MM-DD HH').isSameOrAfter(
      moment().format('YYYY-MM-DD HH'),
    );
  }

  private setDay(date: string) {
    this.visitScheduleForm.day = date;
    this.visitScheduleForm.hour = '';
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));

    this.getEnterprises();
  }

  private async getEnterprises() {
    if (!this.company_id) return;
    const active = true;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.company_id, active);
    this.enterprises = enterprises.map((it: Enterprise) => new Enterprise(it));
    this.getBlocks();
  }

  private close() {
    this.$emit('input', false);
  }

  createVisitSchedule() {
    if (this.$refs.form.validate()) {
      this.visitScheduleForm.create(this.order_id).then(() => {
        this.$snackbar.open({
          text: 'Agendamento criado com sucesso!',
          color: 'success',
          buttonColor: 'white',
        });
        this.close();
        this.refresh();
      });
    }
  }

  refresh() {
    this.$emit('refresh');
  }

  public async getUsers() {
    const groupId = await this.getGroupIdPartner();
    this.users = await userService.getAllUsers({
      params: {
        group_id: groupId,
      },
    });
  }

  public async getGroupIdPartner() {
    const groupIds: Group[] = await userGroupService.getGroups();
    const partner = groupIds.find((it: Group) => it.name === GroupEnum.PARTNER);
    return partner ? partner.id : '';
  }

  public created() {
    this.company_id = this.user.company_id || this.client.company_id;
    if (!this.company_id) {
      this.getCompanies();
      return;
    }

    this.getEnterprises();
    this.getUsers();
  }
}
