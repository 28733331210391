import moment from '@/services/moment/moment.service';
import { ReminderType } from '@/modules/Clients/components/reminders/enums/reminder.enum';
import reminderService from '@/modules/Clients/components/reminders/service/reminder.service';
import clientInformContactService from '../services/client-inform-contact.service';

export class ClientInformContact {
  public id: string;

  public message: string;

  public type: ReminderType;

  public created_at: string;

  public reminders_id: string[];

  public order_id: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.message = data.message || '';
    this.type = data.type || ReminderType.ADMIN;
    this.order_id = data.order_id || '';
    this.reminders_id = data.reminders_id || [];
    this.created_at = data.created_at || '';
  }

  private formatToSave(): any {
    return {
      ...this,
      created_at: this.formatDateToSave(),
    };
  }

  private formatDateToSave() {
    const day = moment(this.created_at).format('YYYY-MM-DD');
    return day;
  }

  public create(order_id: string) {
    const data = {
      ...this.formatToSave(),
      order_id,
    };
    return clientInformContactService.informContact(data);
  }

  public inform(order_id: string) {
    const data = {
      ...this.formatToSave(),
      order_id,
      reminders: this.reminders_id,
    };
    return clientInformContactService.informContact(data);
  }
}
