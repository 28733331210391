
import { Component, Vue, Prop } from 'vue-property-decorator';

import { mapGetters } from 'vuex';
import rulesService from '@/services/rules.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { Group } from '@/modules/Administrative/modules/Users/entities/group.entity';
import { GroupEnum } from '@/modules/Administrative/modules/Users/enum/group.enum';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import userGroupService from '@/modules/Administrative/modules/Users/services/user-group.service';
import { ClientFormTasks } from './entities/client-form-tasks.entity';
import { IClientFormTasks } from './entities/client-form-tasks.interface';
import tasksService from './services/tasks.service';
import TaskListItemComponent from './task-list-item.component.vue';

import TaskBoardComponent from './components/task-board.component.vue';

@Component({
  components: { DeleteDialog, TaskListItemComponent, TaskBoardComponent },
  computed: { ...mapGetters(['user']) },
})
export default class ClientFormTasksComponent extends Vue {
  @Prop({
    type: String,
    default: () => '',
  })
  public readonly companyId!: string;

  public $refs: any;

  public currentTask = new ClientFormTasks();

  public tasks: IClientFormTasks[] = [];

  public users: User[] = [];

  public user!: any;

  public showDialogDelete = false;

  private showTaskBoardDialog: boolean = false;

  public rules = {
    required: rulesService.required,
  };

  public itemToDelete: ClientFormTasks = new ClientFormTasks();

  public get orderId() {
    return this.$route.params.id;
  }

  public async toggleTaskCompleted(content: any) {
    const { data, event } = content;
    data.completed = event;
    if (event) {
      await tasksService.doneTask(data);
      this.getAlltasks();
      return;
    }
    await tasksService.undoTask(data);
    this.getAlltasks();
  }

  public save() {
    if (!this.$refs.form.validate()) return;
    if (this.currentTask.id) {
      this.update();
      return;
    }
    this.create();
  }

  public deleteComment(itemToDelete: IClientFormTasks) {
    this.showDialogDelete = true;
    this.itemToDelete = new ClientFormTasks(itemToDelete);
  }

  public scrollToTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  public editItem(itemToEdit: ClientFormTasks) {
    this.currentTask = new ClientFormTasks(itemToEdit);
    this.scrollToTop();
  }

  public closeDialogDelete() {
    this.showDialogDelete = false;
  }

  public create() {
    if (this.currentTask.users.length < 1) {
      const userCreatedTask = this.users.find(it => it.id === this.user.id);
      this.currentTask.users.push(userCreatedTask);
    }
    this.currentTask
      .create(this.orderId)
      .then(() => {
        this.$snackbar.open({
          text: 'tarefa adicionada com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAlltasks();
        this.getUsers();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar a tarefa',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.currentTask = new ClientFormTasks();
      });
  }

  public update() {
    this.currentTask
      .update()
      .then(() => {
        this.$snackbar.open({
          text: 'tarefa alterada com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAlltasks();
        this.getUsers();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar a tarefa',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.currentTask = new ClientFormTasks();
      });
  }

  public deleteItem() {
    this.itemToDelete
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'tarefa deletada com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAlltasks();
        this.getUsers();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao apagar a tarefa',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  public async getAlltasks() {
    const { id } = this.$route.params;
    const tasks: any[] = await tasksService.getAllByClientId(id);

    if (this.$refs.taskBoard && this.$refs.taskBoard.refreshBoard) {
      this.$refs.taskBoard.refreshBoard();
    }
    this.tasks = tasks
      .map((it: any) => new ClientFormTasks(it))
      .sort((a, b) => {
        if (a.completed === b.completed) {
          return 0;
        }
        if (a.completed) {
          return 1;
        }
        return -1;
      });
  }

  public async getGroupIdPartner() {
    const groupIds: Group[] = await userGroupService.getGroups();
    const partner = groupIds.find((it: Group) => it.name === GroupEnum.PARTNER);
    return partner ? partner.id : '';
  }

  public async getUsers() {
    const groupId = await this.getGroupIdPartner();
    this.users = await userService.getAllUsers({
      params: {
        group_id: groupId,
      },
    });
    if (!this.currentTask.users.length) {
      const currentUser = this.users.find(it => it.id === this.user.id);
      this.currentTask.users.push(currentUser);
    }
  }

  public created() {
    this.getAlltasks();
    this.getUsers();
  }
}
