
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../entities/client-form.entity';
import { Timeline } from '../../entities/timeline.entity';
import ChangeStatusDateDialog from './change-status-date-dialog.component.vue';

@Component({
  components: {
    ChangeStatusDateDialog,
  },
})
export default class ClientFormTimeline extends Vue {
  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public readonly client!: ClientForm;

  public showDialogChangeDates: boolean = false;

  public statusToChangeDate: any = {};

  private get timeline() {
    return this.client.timeline;
  }

  private get isManager() {
    return this.$permissionAccess.group.isSuperAdmin || this.$permissionAccess.isManager;
  }

  openDialogChangeDates(timelineItem: Timeline) {
    this.statusToChangeDate = { ...timelineItem };
    this.showDialogChangeDates = true;
  }

  refresh() {
    this.$emit('refresh');
  }
}
