
import { Component, Vue, Prop } from 'vue-property-decorator';
import TimePicker from '@/components/date-picker/time-picker.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import moment from '@/services/moment/moment.service';
import statusService from '@/modules/Administrative/modules/Status/services/status.service';
import rulesService from '@/services/rules.service';
import { Timeline } from '../../entities/timeline.entity';

@Component({ components: { DatePicker, TimePicker, AlertError } })
export default class ChangeStatusDateDialogComponent extends Vue {
  public $refs: any;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  public readonly statusToChangeDate!: Timeline;

  private errors: any = {};

  public rules = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  get hour() {
    return moment(this.statusToChangeDate.created_at).format('HH:mm');
  }

  public allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  public confirm() {
    if (!this.$refs.form.validate()) return;
    this.$emit('confirm');
  }

  public close() {
    this.$emit('input', false);
  }

  public setDay(date: string) {
    const hour = this.statusToChangeDate.created_at.split(' ')[1];

    const newDate = moment(`${date} ${hour}`).format('YYYY-MM-DD HH:mm');

    this.statusToChangeDate.created_at = newDate;
  }

  public setHour(hour: string) {
    const day = this.statusToChangeDate.created_at.split(' ')[0];

    const newDate = moment(`${day} ${hour}`).format('YYYY-MM-DD HH:mm');

    this.statusToChangeDate.created_at = newDate;
  }

  saveNewDate() {
    const { id } = this.$route.params;
    statusService
      .changeDate(id, this.statusToChangeDate)
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Data alterada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.refresh();
        this.close();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao alterar a data',
          color: 'danger',
          buttonColor: '#fff',
        });
        if (err.response.data.errors && err.response.data.errors.length) {
          this.errors = err.response.data.errors;
          return;
        }

        this.errors = { erro: [err.response.data.message] };

        throw new Error(err);
      });
  }

  refresh() {
    this.$emit('refresh');
  }
}
