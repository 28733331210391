export enum TimelineColors {
  'sub_status' = 'info ',
  status = 'primary',
  transferred = 'info',
  changed_status = 'info',
}

export enum TimelineIcons {
  'sub_status' = 'label',
  status = 'local_offer',
  transferred = 'label',
  changed_status = 'label',
}

export enum TimelineLabel {
  'sub_status' = 'Substatus',
  status = 'Status',
  transferred = 'Transferido',
  changed_status = 'Alteração na data do status',
}

export enum TimelineValues {
  'DISTRACT' = 'Desistência',
  'GIVE_UP' = 'Distrato',
  'TRANSFERRED' = 'Transferido',
  'CHANGED_STATUS' = 'Alteração na data do status ',
}
