
import { Component, Vue, Prop } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';
import { ClientBankReferences } from '../../entities/client-bank-references.entity';

@Component
export default class ClientBankReferencesDialog extends Vue {
  private bankingReference: ClientBankReferences = new ClientBankReferences({});

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private addBankingReference() {
    this.clientForm.banking_references.push(this.bankingReference);

    this.bankingReference = new ClientBankReferences({});
    this.closeDialog();
  }
}
