
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';

@Component
export default class ClientNotesComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;
}
