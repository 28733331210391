
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import rulesService from '../../../../../services/rules.service';
import MaritalStatusComponent from '../../marital-status/marital-status.component.vue';
import DatePicker from '../../../../../components/date-picker/date-picker.component.vue';
import AddressComponent from '../../../../../components/address/address.component.vue';
import { DefaultPersonForm } from '../../default-person-form/entities/default-person-form.entity';
import { ClientTypeNameEnum, ClientTypeValueEnum } from '../enums/client-type.enum';
import { ClientForm } from '../entities/client-form.entity';
import { StatusEnum } from '../enums/status.enum';
import { AddressViaCepInterface } from '../../../../../components/address/interfaces/address-via-cep.interface';
import { Address } from '../../../../../components/address/entities/address.entity';
import addressService from '../../../../../components/address/services/address.service';
import RecoverProposalDialog from './recover-proposal-dialog/recover-proposal-dialog.component.vue';
import clientService from '../../../services/client.service';
import ClientFormContract from './contracts/client-form-contract.component.vue';
import orderService from '../../../../Administrative/modules/Orders/services/order.service';
import { Utils } from '../../../../../services/utils/utils.service';
import { RecoverProposal } from '../entities/recover-proposal.entity';

@Component({
  components: {
    MaritalStatusComponent,
    AddressComponent,
    DatePicker,
    ClientFormContract,
    RecoverProposalDialog,
  },
  computed: { ...mapGetters(['user']) },
})
export default class ClientFormRegister extends Vue {
  public $refs: any;

  public user: any;

  @Prop({ type: Object, default: () => new DefaultPersonForm() })
  private readonly clientFormRegister!: DefaultPersonForm;

  @Prop({ type: String, default: () => '' })
  private readonly companyId!: string;

  @Prop({ type: Number, default: () => 1 })
  private readonly proponentNumber!: number;

  @Prop({ type: Object, default: () => new Status() })
  private readonly status!: Status;

  public loading: boolean = false;

  private showRecoverProposalDialog: boolean = false;

  private proposalToRecover: RecoverProposal = new RecoverProposal();

  public error: string = '';

  private statusEnum: any = StatusEnum;

  private recoverData: RecoverProposal = new RecoverProposal();

  private civilState: Array<any> = [];

  private genders: Array<any> = [
    {
      id: 'MALE',
      description: 'Masculino',
    },
    {
      id: 'FEMALE',
      description: 'Feminino',
    },
  ];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private hiddenItemsAddress = '';

  private states: any[] = [];

  private cities: any[] = [];

  private requiredFields = {
    cep: [this.rules.requiredOnProposal],
    address: [this.rules.requiredOnProposal],
    number: [this.rules.requiredOnProposal],
    neighborhood: [this.rules.requiredOnProposal],
    state: [this.rules.requiredOnProposal],
    city: [this.rules.requiredOnProposal],
  };

  private moreItems: boolean = false;

  private itemsType = [
    ClientTypeNameEnum.PROPONENT,
    ClientTypeNameEnum.GUARANTOR,
    ClientTypeNameEnum.SPOUSE,
  ];

  private get phoneRequiredRules() {
    return this.isGuarantorPerson ? [] : [this.rules.required];
  }

  private get currentUserisOwner() {
    return this.currentUserId === this.proposalToRecover.person.created_by.id;
  }

  private get currentUserId(): string {
    return this.user.id;
  }

  private get isAddressByCep() {
    return !this.clientFormRegister.address.cep;
  }

  private get rules() {
    return {
      email: rulesService.email,
      required: rulesService.required,
      greaterThanOrEqualZero: rulesService.greaterThanOrEqualZero,
      requiredOnProposal: this.requiredOnProposal,
      minLengthPhone: rulesService.minLengthPhone,
      minLengthResidencialPhone: rulesService.minLengthResidencialPhone,
    };
  }

  private get requiredOnProposal() {
    return this.status.type === StatusEnum.PROPOSAL ? rulesService.required : true;
  }

  private get proposalOrAfterStatus() {
    const statusAllowed = [
      StatusEnum.CONTRACT,
      StatusEnum.SOLD,
      StatusEnum.CLOSED,
      StatusEnum.DISTRACT,
      StatusEnum.PROPOSAL,
      StatusEnum.GIVE_UP,
    ];
    return statusAllowed.some((it: string) => it === this.status.type);
  }

  private resetValuesWithVMoney() {
    this.$refs.average_wage.$el.getElementsByTagName('input')[0].value = 0;
    this.$refs.fgts_value.$el.getElementsByTagName('input')[0].value = 0;
    this.clientFormRegister.fgts_value = '10';
  }

  private get showMoreItemsButton() {
    const statusAllowed = [StatusEnum.INITIAL_CONTACT];
    return statusAllowed.some((it: string) => it === this.status.type);
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get labelFields() {
    return this.status.type === StatusEnum.INITIAL_CONTACT
      ? { state: 'Estado de nascimento', city: 'Cidade de nascimento' }
      : undefined;
  }

  private get isFisrtProponentClient() {
    return this.proponentNumber > 0;
  }

  private get isGuarantorPerson() {
    return this.clientFormRegister.type === ClientTypeNameEnum.GUARANTOR;
  }

  private get isSpousePerson() {
    return this.clientFormRegister.type === ClientTypeNameEnum.SPOUSE;
  }

  private toggleMoreItems() {
    this.moreItems = !this.moreItems;
  }

  private deleteItem() {
    this.$emit('delete-client', this.clientFormRegister.temporaryId);
  }

  private goToProposal(id: string) {
    const routeData = this.$router.resolve({
      name: 'client-edit',
      params: {
        id,
      },
    });
    window.open(routeData.href, '_blank');
  }

  public getPersonsDataByPhone() {
    if (this.isEdit) return;
    this.error = '';
    if (this.isFisrtProponentClient) return;
    if (!this.clientFormRegister.cellphone || this.clientFormRegister.cellphone.length < 14) return;
    this.loading = true;
    orderService
      .validPerson({
        cellphone: Utils.removeCharactersText(this.clientFormRegister.cellphone),
        company_id: this.companyId,
      })
      .then((response: any) => {
        this.proposalToRecover = new RecoverProposal(response);
        this.showRecoverProposalDialog = this.proposalToRecover.orders.length >= 1;
      })
      .catch((err: any) => {
        if (err && err.response && err.response.data.message) {
          this.error = err.response.data.message;
          return;
        }
        this.error = 'Ocorreu um erro ao validar o cliente';
        this.$snackbar.open({
          text: this.error,
          color: 'danger',
          buttonColor: 'white',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public validatePerson = debounce(() => {
    this.getPersonsDataByPhone();
  }, 1000);

  private updateClient() {
    if (!this.currentUserisOwner) {
      this.$emit('set-notify-user-that-recover-data');
    }
    this.$emit('update-client-data', this.proposalToRecover.person);
    this.showRecoverProposalDialog = false;
  }

  private validate() {
    return this.$refs.form.validate();
  }

  private async setAddressByCep(address: Address) {
    const fullAddress = { ...address, number: this.clientFormRegister.address.number };
    this.clientFormRegister.address = new Address(address);
  }

  private async getStates() {
    if (this.clientFormRegister.born_city.city.id) {
      this.getCitiesByStateId(this.clientFormRegister.born_city.city.state.id);
    }
    this.states = await addressService.getStates();
  }

  private async getCitiesByStateId(stateId: string) {
    const state = stateId || this.clientFormRegister.born_city.city.state.id;
    this.cities = await addressService.getCitiesByStateId(state);
  }

  private async getCivilStates() {
    this.civilState = await clientService.getCivilStates();
  }

  private created() {
    this.getStates();
    this.getCivilStates();
  }
}
