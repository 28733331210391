
import { Component, Prop, Vue } from 'vue-property-decorator';

import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { ClientForm } from '../../entities/client-form.entity';
import ClientBankingReferencesDialog from './client-banking-references-dialog.component.vue';
import { ClientBankReferences } from '../../entities/client-bank-references.entity';

@Component({
  components: {
    ClientBankingReferencesDialog,
    DeleteDialog,
  },
})
export default class ClientBankingReferencesDataComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private itemToDelete: any;

  private bankingReferencesHeaders: any = [
    { text: 'Nome', value: 'name', width: '35%' },
    { text: 'Agência.', value: 'branch', width: '25%' },
    { text: 'Conta', value: 'account', width: '25%' },
    {
      text: 'Ações',
      width: '15%',
      align: 'center',
      value: 'actions',
    },
  ];

  private showDialog: boolean = false;

  private showDialogDelete: boolean = false;

  private setShowDialogDelete(bankingReferences: ClientBankReferences) {
    this.itemToDelete = bankingReferences;
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private openDialog() {
    this.showDialog = !this.showDialog;
  }

  private deleteItem() {
    const index = this.clientForm.banking_references.findIndex(
      it => it.id === this.itemToDelete.id,
    );
    this.clientForm.banking_references.splice(index, 1);

    this.closeDialogDelete();
  }
}
