
import DeleteDialogComponent from '@/components/dialogs/delete-dialog.component.vue';
import moment from '@/services/moment/moment.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../form/entities/client-form.entity';
import VisitScheduleListComponent from './visit-schedule-list.component.vue';
import VisitScheduleFormComponent from './visit-schedule-form.component.vue';
import { VisitScheduleList } from './entities/visit-schedule-list';
import visitSchedulesService from './services/visit-schedules.service';
import VisitScheduleConfirmComponent from './visit-schedule-confirm.component.vue';

@Component({
  components: {
    VisitScheduleListComponent,
    VisitScheduleFormComponent,
    DeleteDialogComponent,
    VisitScheduleConfirmComponent,
  },
})
export default class VisitScheduleComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly client!: ClientForm;

  public showVisitSchedules: boolean = false;

  public visitList: VisitScheduleList[] = [];

  public loadingData: boolean = false;

  public scheduleToDeleteId: string = '';

  public scheduleToConfirmId: string = '';

  public showDialogDelete: boolean = false;

  public showDialogConfirm: boolean = false;

  openDialogConfirm(scheduleId: string) {
    this.scheduleToConfirmId = scheduleId;
    this.showDialogConfirm = true;
  }

  setShowVisitSchedules() {
    this.showVisitSchedules = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  deleteSchedule(scheduleId: string) {
    this.scheduleToDeleteId = scheduleId;
    this.showDialogDelete = true;
  }

  private formatDateToSave(day: string, hour: string) {
    const dayFormatted = moment(day).format('YYYY-MM-DD');
    return `${dayFormatted} ${hour}:00`;
  }

  markAsConcluded(confirmVisitSchedule: { day: string; hour: string }) {
    const visited_at = this.formatDateToSave(confirmVisitSchedule.day, confirmVisitSchedule.hour);
    visitSchedulesService
      .markAsConcluded(this.scheduleToConfirmId, visited_at)
      .then(() => {
        this.$snackbar.open({
          text: 'Visita marcada como concluída com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.getVisitSchedules();
        this.showDialogConfirm = false;
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao marcar como concluída',
          color: 'danger',
          buttonColor: '#fff',
        });
      });
  }

  getVisitSchedules() {
    this.loadingData = true;
    visitSchedulesService
      .getAllSchedulesVisits(this.client.id)
      .then((data: VisitScheduleList[]) => {
        const response = data.map(it => new VisitScheduleList(it));
        this.visitList = response;
      })
      .finally(() => {
        this.loadingData = false;
      });
  }

  deleteScheduleConfirm() {
    visitSchedulesService
      .deleteSchedule(this.scheduleToDeleteId)
      .then(() => {
        this.getVisitSchedules();
        this.$snackbar.open({
          text: 'Visita deletada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao deletar visita',
          color: 'danger',
          buttonColor: '#fff',
        });
      })
      .finally(() => {
        this.showDialogDelete = false;
      });
  }

  created() {
    this.getVisitSchedules();
  }
}
