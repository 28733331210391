import http from '@/services/http.service';

class SimulationProposalService {
  createSimulationProposal(simulationProposal: any) {
    return http.post('/order/proposal-simulation', simulationProposal).then(({ data }) => data.data);
  }

  getSimulationProposalById(order_id: string) {
    return http.get(`/order/${order_id}/proposal-simulation`).then(({ data }) => data.data);
  }
}

export default new SimulationProposalService();
