
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';

import paymentConditionService from '@/modules/Administrative/modules/PaymentConditions/services/payment-condition.service';
import { PaymentCondition } from '../../../../../Administrative/modules/PaymentConditions/entities/payment-condition.entity';
import { ClientPaymentCondition } from '../../entities/client-payment-condition.entity';
import orderService from '../../../../../Administrative/modules/Orders/services/order.service';
import { ClientForm } from '../../entities/client-form.entity';
import DatePicker from '../../../../../../components/date-picker/date-picker.component.vue';
import rulesService from '../../../../../../services/rules.service';
import ClientPaymentConditionItem from './client-payment-condition-item.component.vue';
import PaymentsInstallmentDialog from './payments-installment.dialog.vue';
import { IPaymentInstallments } from '../../interfaces/payment-installments.interface';
import { IPaymentRecalculate } from '../../interfaces/payment-recalculate.interface';

@Component({ components: { DatePicker, ClientPaymentConditionItem, PaymentsInstallmentDialog } })
export default class ClientPaymentConditionComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly value!: ClientPaymentCondition[];

  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public readonly client!: ClientForm;

  private showDialog: boolean = false;

  private contentToDialog: ClientPaymentCondition = new ClientPaymentCondition();

  private installmentsEdited: IPaymentInstallments[] = [];

  private installmentsRest: IPaymentInstallments[] = [];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private expanded: any = [];

  private showResultCalculate: boolean = false;

  private loaderButtonCalculate: boolean = false;

  private typeButtonCalculate: string = 'info';

  private textResultCalculate: string = 'Aprovar condição de pagamento';

  private error: string = '';

  private successMessage: string = '';

  private rules = {
    required: rulesService.required,
  };

  private paymentConditions: PaymentCondition | ClientPaymentCondition[] = [];

  private headers: any = [
    { text: '', value: 'action', width: '5%' },
    { text: 'Descrição', value: 'descriptionFormatted', width: '90%' },
    { text: '', value: 'data-table-expand' },
  ];

  private get hasReserve() {
    return this.client.unit.reserve && this.client.unit.reserve.id;
  }

  private get hasPaymentChecked() {
    return !!this.value.length && this.allItemsSelectedIsValid && !!this.client.unit.id;
  }

  private get allItemsSelectedIsValid() {
    return this.value.every((it: ClientPaymentCondition) => it.isValid());
  }

  private get iconButtonCalculate() {
    return this.typeButtonCalculate === 'success' ? 'check_circle_outline' : 'error';
  }

  private allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  private openDialog(payment: ClientPaymentCondition) {
    this.showDialog = true;
    this.contentToDialog = payment;
    this.installmentsRest = [...payment.installments];
  }

  private setItemToEdit(item: IPaymentInstallments) {
    const index = this.installmentsEdited.findIndex(
      (it: IPaymentInstallments) => it.reference === item.reference,
    );
    if (index >= 0) {
      this.installmentsEdited.splice(index, 1, item);
      return;
    }
    this.installmentsEdited.push(item);
    const idx = this.installmentsRest.findIndex(
      (it: IPaymentInstallments) => it.reference === item.reference,
    );
    if (idx >= 0) {
      this.installmentsRest.splice(idx, 1);
    }
  }

  private setInstallments(payment: ClientPaymentCondition) {
    const index = this.client.payment_conditions.findIndex(
      (it: ClientPaymentCondition) => it.id === payment.id,
    );

    this.client.payment_conditions.splice(index, 1, payment);
  }

  private formatToRecalculate(): IPaymentRecalculate {
    return {
      ...this.contentToDialog.formatToCalculate(),
      installments_edited: [...this.installmentsEdited],
      installments_rest: [...this.installmentsRest],
    };
  }

  private closeDialog() {
    this.showDialog = false;
  }

  private isValidDay(date: string) {
    return true;
  }

  private setExpandItem(paymentCondition: PaymentCondition) {
    if (this.expanded[0] === paymentCondition) {
      this.expanded = [];
      return;
    }
    this.expanded = [paymentCondition];
  }

  private async getPaymentCondition() {
    const payments = await paymentConditionService.getPaymentsByEnterpriseId(
      this.client.enterprise_id,
    );
    const allPayments = payments.map((it: any) => ({ ...it, payment_condition_id: it.id }));

    this.paymentConditions = this.formatPayments(allPayments, this.client.payment_conditions);
  }

  private addOrderIdInPayments(payments: any) {
    return payments.map(
      (condition: ClientPaymentCondition) => new ClientPaymentCondition({ ...condition, order_id: this.client.id }),
    );
  }

  private getDataOfSelectedPayment(payment: ClientPaymentCondition) {
    return this.value.find(
      (value: ClientPaymentCondition) => value.payment_condition_id === payment.id,
    );
  }

  private removeDuplicatedItems(payments: ClientPaymentCondition[] = []) {
    return payments.reduce((acc: ClientPaymentCondition[], value: ClientPaymentCondition) => {
      const index: number = acc.findIndex(
        (it: ClientPaymentCondition) => it.payment_condition_id === value.payment_condition_id,
      );
      if (index === -1) {
        acc.push(value);
        return acc;
      }
      return acc;
    }, []);
  }

  private formatPayments(payments: any, clientPaymentConditions: ClientPaymentCondition[]) {
    const paymentsWithOrderId = this.addOrderIdInPayments(payments);

    paymentsWithOrderId.unshift(...this.client.payment_conditions);

    const allPayments: ClientPaymentCondition[] = this.removeDuplicatedItems(paymentsWithOrderId);

    if (this.value.length < 1) return allPayments;

    return allPayments.map((it: ClientPaymentCondition) => {
      const selected = this.getDataOfSelectedPayment(it);
      return selected || it;
    });
  }

  private resetButton() {
    this.showResultCalculate = false;
    this.loaderButtonCalculate = false;
    this.typeButtonCalculate = 'info';
    this.textResultCalculate = 'Aprovar condição de pagamento';
    this.successMessage = '';
    this.error = '';
  }

  private handlePaymentCondition(paymentCondition: PaymentCondition) {
    this.resetButton();
    this.$emit('input', paymentCondition);
  }

  private isChecked(paymentCondition: ClientPaymentCondition) {
    return this.value.some(
      (it: ClientPaymentCondition) => it.payment_condition_id === paymentCondition.id
        || it.payment_condition_id === paymentCondition.payment_condition_id,
    );
  }

  private recalculateInstallments() {
    const formData: any = this.formatToRecalculate();
    orderService
      .recalculate(formData)
      .then(({ installments }) => {
        this.contentToDialog.installments = [];
        this.installmentsEdited = [];
        this.$nextTick(() => {
          this.contentToDialog.installments = [...installments];
          this.installmentsRest = [...installments];
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao recalcular as parcelas',
          color: 'danger',
          buttonColor: 'white',
        });
      });
  }

  private formatToCalculate() {
    return this.value.map((it: ClientPaymentCondition) => ({
      ...it.formatToCalculate(),
    }));
  }

  private checkPaymentCondition() {
    const { id } = this.$route.params;
    this.successMessage = '';

    const payload = {
      order_id: id,
      unit_id: this.client.unit.id,
      payment_conditions: this.formatToCalculate(),
    };

    this.loaderButtonCalculate = true;
    orderService
      .validatePaymentCondition(payload)
      .then((data) => {
        this.typeButtonCalculate = 'success';
        this.textResultCalculate = 'A condição de pagamento é valida';
        this.error = '';
        this.successMessage = data.message;
      })
      .catch((err: any) => {
        if (err && err.response && err.response.data.message) {
          this.error = err.response.data.message;
          this.typeButtonCalculate = 'danger';
          this.textResultCalculate = 'A condição de pagamento não é valida';
        }
      })
      .finally(() => {
        this.showResultCalculate = true;
        this.loaderButtonCalculate = false;
      });
  }

  private created() {
    this.getPaymentCondition();
  }
}
