
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SimulationProposalList } from './entities/simulation-proposal-list';
import simulationProposalService from './services/simulation-proposal.service';

@Component
export default class SimulationHistoriesComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly order_id!: string;

  public loadingData: boolean = false;

  public proposalsList: SimulationProposalList[] = [];

  private headers: any = [
    { text: 'Simulação', value: 'name', width: '15%' },
    {
      text: 'Data',
      value: 'createdAtFormatted',
      align: 'center',
      width: '15%',
    },
    {
      text: 'Ações',
      value: 'actions',
      width: '15%',
      align: 'center',
    },
  ];

  viewProposal(proposal: SimulationProposalList) {
    proposal.openInNewTab();
  }

  private confirm() {
    this.$emit('confirm');
  }

  private close() {
    this.$emit('input', false);
  }

  getSimulationHistories() {
    simulationProposalService
      .getSimulationProposalById(this.order_id)
      .then((data: SimulationProposalList[]) => {
        const response = data.map(it => new SimulationProposalList(it));
        this.proposalsList = response;
      });
  }

  created() {
    this.getSimulationHistories();
  }
}
