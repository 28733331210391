
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import RulesService from '@/services/rules.service';
import { Checklist } from '@/modules/Administrative/modules/Checklists/entities/checklist.entity';
import { StatusEnum } from '../enums/status.enum';
import { ClientForm } from '../entities/client-form.entity';
import clientService from '../../../services/client.service';
import ClientPaymentCondition from './payment-conditions/client-payment-condition.component.vue';
import { ProductInterest } from '../../../../Administrative/modules/ProductInterests/entities/product-interest.entity';
import { PaymentCondition } from '../../../../Administrative/modules/PaymentConditions/entities/payment-condition.entity';
import ClientFormPersons from './client-form-persons-data.component.vue';
import ClientFormChannel from './channel/client-form-channel.component.vue';
import ClientFormProductInterests from './product-interests/client-form-product-interests.component.vue';
import ClientFormChecklists from './checklists/client-form-checklists.component.vue';
import ClientFormGeneralData from './general-data/client-form-general-data.component.vue';
import ClientFormNotification from './current-process/client-form-notification.component.vue';
import ClientAdditionalData from './additional-data/client-additional-data.component.vue';
import ClientFormContract from './contracts/client-form-contract.component.vue';
import ClientFormRatingComponent from './rating/client-form-rating.component.vue';

@Component({
  components: {
    ClientFormPersons,
    ClientFormRatingComponent,
    ClientFormChannel,
    ClientFormProductInterests,
    ClientFormChecklists,
    ClientFormGeneralData,
    ClientPaymentCondition,
    ClientFormNotification,
    ClientFormContract,
    ClientAdditionalData,
  },
})
export default class ClientFormWrapper extends Vue {
  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public readonly client!: ClientForm;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly loadingClientForm!: boolean;

  public $refs: any;

  public StatusEnum = StatusEnum;

  private rules = {
    required: RulesService.required,
  };

  private get isPaymentConditionVisibile() {
    if (!this.client.id || this.$loader.isOpen) return false;
    const statusAllowed = [StatusEnum.PROPOSAL];
    return statusAllowed.some((status: string) => status === this.client.status.type);
  }

  private get showContractCard() {
    return this.client.status.type === StatusEnum.CONTRACT;
  }

  private get showNotificationCard() {
    return this.client.getProcessRealized().length > 0;
  }

  private validate() {
    return this.$refs && this.$refs.form ? this.$refs.form.validate() : false;
  }

  private setNotifyUserThatRecoverData() {
    this.$emit('set-notify-user-that-recover-data');
  }

  private sendProposal() {
    this.$emit('send-proposal');
  }

  private sendGiveUp() {
    this.$emit('send-give-up');
  }

  private sendDistract() {
    this.$emit('send-distract');
  }

  private sendContract() {
    this.$emit('send-contract');
  }

  private sendReserve() {
    this.$emit('send-reserve');
  }

  private handleProductInterest(product: ProductInterest) {
    const index = this.client.product_interests.findIndex((it: string) => it === product.id);
    const hasItem = index >= 0;
    if (hasItem) {
      this.client.removeProductInterest(product);
      return;
    }
    this.client.addProductInterest(product);
  }

  private handleChecklist(checklist: Checklist) {
    const index = this.client.checklists.findIndex((it: string) => it === checklist.id);
    const hasItem = index >= 0;
    if (hasItem) {
      this.client.removeChecklist(checklist);
      return;
    }
    this.client.addChecklist(checklist);
  }

  private handlePaymentCondition(paymentCondition: any) {
    this.client.handlePaymentCondition(paymentCondition);
  }
}
