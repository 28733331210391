import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import { IPaymentRecalculate } from '@/modules/Clients/components/form/interfaces/payment-recalculate.interface';
import { Order } from '../entities/order.entity';

class OrderService {
  public create(payload: Order) {
    return http.post('/order', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Order) {
    return http.put(`/order/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(orderId: string) {
    return http.delete(`/order/${orderId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllOrders() {
    return http.get('/order').then(({ data }: AxiosResponse) => data.data);
  }

  public getOrderById(id: string) {
    return http.get(`/order/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getOrderStatuses() {
    return http.get('/order/status').then(({ data }: AxiosResponse) => data.data);
  }

  public getOrderFinalStatuses(company_id: string) {
    return http
      .get('/order/final-status', {
        params: {
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public sendProposal(order_id: string) {
    return http.post('/order/send-proposal', { order_id });
  }

  public calculate(payment: any) {
    return http
      .post('/order/payment-condition/calculate', payment)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public recalculate(payment: IPaymentRecalculate) {
    return http
      .post('/order/payment-condition/recalculate', payment)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public validPerson(payload: any) {
    return http
      .post('/order/person/validate', payload)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public validatePaymentCondition(payments: any) {
    return http
      .post('/order/payment-condition/validate', payments)
      .then(({ data }: AxiosResponse) => data);
  }

  public reserveUnit(payload: any) {
    return http.post('/order/reserve', payload);
  }

  public deleteReservedUnit(payload: any) {
    return http.post('/order/delete-reserve', payload);
  }

  public sendContractByEmail(order_id: string) {
    return http.post('/order/send-contract/', { order_id });
  }
}

export default new OrderService();
