import HandleImage from '@/modules/shared/services/handle-image.service';
import distractService from '@/modules/Clients/services/distract.service';
import clientService from '@/modules/Clients/services/client.service';
import { AttachmentEnum } from '../enums/attachment.enum';

export class ClientDistract {
  public reason_id: string;

  public justification: string;

  public attachment: any[];

  constructor(data: any = {}) {
    this.reason_id = data.reason_id || '';
    this.justification = data.justification || '';
    this.attachment = data.attachment || [];
  }

  private async formatToSend() {
    const file = this.attachment;
    return {
      ...this,
      attachments: [
        {
          file,
          name: 'Arquivo de distrato',
          type: AttachmentEnum.DISTRACT,
        },
      ],
    };
  }

  public async save(orderId: string) {
    const formData = new FormData();
    formData.append('reason_id', this.reason_id);
    formData.append('justification', this.justification);
    formData.append('file', this.attachment[0]);
    return distractService.save(orderId, formData);
  }

  public async setDistract(orderId: string) {
    const formData = new FormData();
    formData.append('file', this.attachment[0]);
    formData.append('justification', this.justification);
    return clientService.setDistract(orderId, formData);
  }
}
