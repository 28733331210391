import moment from '@/services/moment/moment.service';

export class VisitScheduleList {
  public description: string;

  public id: string;

  public title: string;

  public visit_at: string;

  public visited_at: string;

  constructor(data: any) {
    this.description = data.description;
    this.id = data.id;
    this.title = data.title;
    this.visit_at = data.visit_at;
    this.visited_at = data.visited_at;
  }

  public get dateFormatted() {
    return this.visit_at ? moment(this.visit_at).format('DD/MM/YYYY') : '-';
  }

  public get visitedDateFormatted() {
    return this.visited_at ? moment(this.visited_at).format('DD/MM/YYYY') : '-';
  }

  public get status() {
    return this.visited_at ? 'Visitado' : 'Aguardando visita';
  }
}
