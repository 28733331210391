
import { Vue, Prop, Component } from 'vue-property-decorator';

import { ClientForm } from '../../entities/client-form.entity';

@Component
export default class ClientHouseInfoDataComponent extends Vue {
  private houseTypes = [
    {
      id: 'Própria',
      name: 'Própria',
    },
    {
      id: 'Alugada',
      name: 'Alugada',
    },
  ];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;
}
