
import { Component, Prop, Vue } from 'vue-property-decorator';

import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { ClientForm } from '../../entities/client-form.entity';
import ClientChildrenDialog from './client-children-dialog.component.vue';
import { ClientChildren } from '../../entities/client-children.entity';

@Component({
  components: {
    ClientChildrenDialog,
    DeleteDialog,
  },
})
export default class ClientChildrenDataComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private itemToDelete: any;

  private childrenToEdit: ClientChildren = new ClientChildren();

  private childrenHeaders: any = [
    { text: 'Nome', value: 'name', width: '15%' },
    { text: 'Data de Nascimento.', value: 'birthDateFormatted', width: '15%' },
    {
      text: 'Ações',
      width: '10%',
      align: 'center',
      value: 'actions',
    },
  ];

  private showDialog: boolean = false;

  private showDialogEdit: boolean = false;

  private showDialogDelete: boolean = false;

  private setShowDialogDelete(children: ClientChildren) {
    this.itemToDelete = children;
    this.showDialogDelete = true;
  }

  private saveEdit(children: ClientChildren) {
    const childrenToEditIndex = this.clientForm.children.findIndex(
      (it: ClientChildren) => it.id === children.id,
    );

    if (childrenToEditIndex >= 0) {
      this.clientForm.children.splice(childrenToEditIndex, 1, children);
    }
  }

  private addChild(children: ClientChildren) {
    this.clientForm.children.push(children);
  }

  private setClientChildrenToEdit(children: ClientChildren) {
    this.childrenToEdit = children;
    this.showDialogEdit = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private openDialog() {
    this.showDialog = !this.showDialog;
  }

  private deleteItem() {
    const index = this.clientForm.children.findIndex(it => it.id === this.itemToDelete.id);
    this.clientForm.children.splice(index, 1);

    this.closeDialogDelete();
  }
}
