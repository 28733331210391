
import { Component, Vue, Prop } from 'vue-property-decorator';
import ListSkeleton from '@/modules/shared/components/list-skeleton.component.vue';
import { VisitScheduleList } from './entities/visit-schedule-list';

@Component({
  components: {
    ListSkeleton,
  },
})
export default class VisitScheduleListComponent extends Vue {
  @Prop({
    type: String,
    default: () => '',
  })
  private readonly order_id!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public loadingData!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  public visitList!: VisitScheduleList;

  private headers: any = [
    { text: 'Titulo', value: 'title', width: '15%' },
    {
      text: 'Data para visita',
      value: 'dateFormatted',
      align: 'center',
      width: '15%',
    },
    {
      text: 'Visitado em',
      value: 'visitedDateFormatted',
      align: 'center',
      width: '15%',
    },
    {
      text: 'Status',
      value: 'status',
      width: '15%',
      align: 'center',
    },
    {
      text: 'Ações',
      value: 'actions',
      width: '15%',
      align: 'center',
    },
  ];

  deleteItem(schedule: VisitScheduleList) {
    this.$emit('delete-schedule', schedule.id);
  }

  markAsConcluded(schedule: VisitScheduleList) {
    this.$emit('mark-as-concluded', schedule.id);
  }

  private confirm() {
    this.$emit('confirm');
  }

  private close() {
    this.$emit('input', false);
  }
}
