import handleImageService from '@/modules/shared/services/handle-image.service';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';
import Attachment from '../modules/shared/entities/attachment.entity';

class DownloadService {
  public async downloadFile(attachment: Attachment | IAttachmentDefault) {
    const element = document.createElement('a');
    element.setAttribute('download', attachment.name);
    const fileBase64 = await handleImageService.convertFileToBase64(attachment.file[0]);
    element.setAttribute('href', fileBase64);
    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      document.body.removeChild(element);
    }, 5000);
  }

  public openInNewTab(file: string) {
    const element = document.createElement('a');
    element.setAttribute('href', file);
    element.setAttribute('target', '__blank');
    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      document.body.removeChild(element);
    }, 5000);
  }
}

export default new DownloadService();
