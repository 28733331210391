
import { Component, Vue, Prop } from 'vue-property-decorator';
import orderService from '@/modules/Administrative/modules/Orders/services/order.service';
import unitsService from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/services/units.service';
import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import enterpriseBlocksService from '@/modules/Administrative/modules/Enterprises/components/form/services/enterprise-blocks.service';
import { IEnterpriseBlocks } from '@/modules/Administrative/modules/Enterprises/components/form/interface/enterprise-blocks.interface';
import { ClientForm } from '../../entities/client-form.entity';
import rulesService from '../../../../../../services/rules.service';
import { StatusEnum } from '../../enums/status.enum';
import clientService from '../../../../services/client.service';
import enterpriseService from '../../../../../Administrative/modules/Enterprises/services/enterprise.service';
import sellingPointService from '../../../../../Administrative/modules/SellingPoints/services/selling-points.service';

import { Company } from '../../../../../Administrative/modules/Companies/entities/company.entity';
import { Enterprise } from '../../../../../Administrative/modules/Enterprises/entities/enterprise.entity';
import { SellingPoint } from '../../../../../Administrative/modules/SellingPoints/entities/selling-point.entity';
import ClientFormAction from '../actions/client-form-actions.component.vue';
import ClientDistractDialog from '../distract/client-distract-dialog.component.vue';

import substatusService from '../../../../../Administrative/modules/Substatus/services/substatus.service';

import { ClientActionEnum } from '../../enums/client-actions.enum';
import ClientReserveDialog from '../reserve/client-reserve-dialog.component.vue';
import { ClientPaymentCondition } from '../../entities/client-payment-condition.entity';
import Attachment from '../../../../../shared/entities/attachment.entity';
import { AttachmentEnum } from '../../enums/attachment.enum';
import ClientGiveUpDialog from '../give-up/client-giveup-dialog.component.vue';
import ClientSendProposalDialog from '../proposal/client-send-proposal-dialog.component.vue';
import ClientSendContractDialog from '../contracts/client-send-contrat-dialog.component.vue';
import ScheduleContactDialog from '../schedule-contact/schedule-contact-dialog.component.vue';
import { ClientReminder } from '../../../reminders/entities/client-reminders.entity';
import { ReminderType, ReminderTo } from '../../../reminders/enums/reminder.enum';
import ScheduleInformContactDialog from '../schedule-contact/schedule-inform-contact-dialog.component.vue';
import { ClientInformContact } from '../schedule-contact/entities/client-inform-contact.entity';

@Component({
  components: {
    ClientFormAction,
    ClientReserveDialog,
    ClientDistractDialog,
    ClientGiveUpDialog,
    ClientSendProposalDialog,
    ClientSendContractDialog,
    ScheduleContactDialog,
    ScheduleInformContactDialog,
  },
})
export default class ClientFormGeneralDataComponent extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private status: Status[] = [];

  private substatus: any[] = [];

  private blocks: IEnterpriseBlocks[] = [];

  private showDialogConfirm: boolean = false;

  private actionToShow: any = {};

  private companies: Company[] = [];

  private enterprises: Enterprise[] = [];

  private sellingPoints: SellingPoint[] = [];

  private units: Unit[] = [];

  private rules = {
    required: rulesService.required,
  };

  private get proposalFile() {
    const file = this.clientForm.attachments.filter(
      (it: Attachment) => it.type === AttachmentEnum.PROPOSAL,
    );
    return file.length ? file[file.length - 1].path : undefined;
  }

  private get showFormAction() {
    return !!this.clientForm.id && this.clientForm.status.type !== StatusEnum.CLOSED;
  }

  private rulesToEnterprise() {
    return this.clientForm.status.type === StatusEnum.PROPOSAL ? [this.rules.required] : [];
  }

  private get actions() {
    return [
      {
        action: ClientActionEnum.DISTRACT,
        name: 'Gerar distrato',
        disabled: this.disableDistract,
      },
      {
        action: ClientActionEnum.GIVE_UP,
        name: 'Desistir',
        disabled: !!this.clientForm.contractFile || !this.clientForm.id || this.isDistractOrGiveUp,
      },
      {
        action: ClientActionEnum.PROPOSAL,
        name: 'Enviar proposta',
        disabled: this.disableProposal,
      },
      {
        action: ClientActionEnum.SEND_CONTRACT_EMAIL,
        name: 'Enviar contrato para o email do cliente',
        disabled: !this.clientForm.contractFile,
      },
      {
        action: ClientActionEnum.SCHEDULE_CONTACT,
        name: 'Agendar contato',
        disabled: false,
      },
      {
        action: ClientActionEnum.INFORM_CONTACT,
        name: 'Informar contato',
        disabled: false,
      },
    ];
  }

  private get proposalOrAfterStatus() {
    const statusAllowed = [
      StatusEnum.CONTRACT,
      StatusEnum.SOLD,
      StatusEnum.CLOSED,
      StatusEnum.DISTRACT,
      StatusEnum.PROPOSAL,
      StatusEnum.GIVE_UP,
    ];
    return statusAllowed.some((it: string) => it === this.clientForm.status.type);
  }

  private get actionsAvailable() {
    return this.actions.filter((it: any) => !it.disabled);
  }

  private get isDistractOrGiveUp() {
    return (
      this.clientForm.status.type === StatusEnum.DISTRACT
      || this.clientForm.status.type === StatusEnum.GIVE_UP
    );
  }

  private get disableProposal() {
    if (!this.hasPaymentsConditionsSaved) return true;
    if (this.clientForm.status.type !== StatusEnum.PROPOSAL) return true;
    return false;
  }

  private get disableDistract() {
    if (
      this.clientForm.status.type !== StatusEnum.SOLD
      && this.clientForm.status.type !== StatusEnum.CONTRACT
    ) {
      return true;
    }
    if (!this.clientForm.contractFile) return true;

    return false;
  }

  private get hasPaymentsConditionsSaved() {
    return this.clientForm.payment_conditions.map((it: ClientPaymentCondition) => it.id).length > 0;
  }

  private async getEnterpriseBlocks() {
    await enterpriseBlocksService
      .getBlocks(this.clientForm.enterprise_id)
      .then((data: IEnterpriseBlocks[]) => {
        this.blocks = data;
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao buscar os dados.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private async getEnterprises() {
    if (!this.clientForm.company_id) return;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(
      this.clientForm.company_id,
    );
    this.enterprises = enterprises.map((it: Enterprise) => new Enterprise(it));
    this.getUnits();
  }

  private async getUnits() {
    if (!this.clientForm.enterprise_id) return;
    const units = await unitsService.getUnitsByEnterpriseId(this.clientForm.enterprise_id);
    this.units = units.map((it: Unit) => new Unit(it));
  }

  private get componentToShowAlert() {
    const components: any = {
      [ClientActionEnum.DISTRACT]: ClientDistractDialog,
      [ClientActionEnum.GIVE_UP]: ClientGiveUpDialog,
      [ClientActionEnum.PROPOSAL]: ClientSendProposalDialog,
      [ClientActionEnum.SEND_CONTRACT_EMAIL]: ClientSendContractDialog,
      [ClientActionEnum.SCHEDULE_CONTACT]: ScheduleContactDialog,
      [ClientActionEnum.INFORM_CONTACT]: ScheduleInformContactDialog,
    };
    return components[this.actionToShow.action];
  }

  private proposalDownload() {
    window.open(this.proposalFile, '__blank');
  }

  public informContactSchedule(reminder: ClientInformContact) {
    reminder
      .inform(this.clientForm.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Contato informado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao informar contato',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogConfirm();
      });
  }

  private openAction(action: string) {
    if (action === ClientActionEnum.DOWNLOAD) {
      this.proposalDownload();
      return;
    }
    this.actionToShow = this.actions.find((it: any) => it.action === action);
    this.showDialogConfirm = true;
  }

  private sendProposal() {
    this.getSubstatusByStatusItem();
    this.$emit('send-proposal');
  }

  private async sendDistract() {
    this.clientForm.status.type = StatusEnum.DISTRACT;
    await this.getStatus();
    this.getSubstatusByStatusItem();
    this.$emit('send-distract');
  }

  private sendReserve() {
    this.$emit('send-reserve');
  }

  private async sendGiveUp() {
    this.clientForm.status.type = StatusEnum.GIVE_UP;
    await this.getStatus();
    this.$emit('send-give-up');
  }

  private async sendContract() {
    orderService
      .sendContractByEmail(this.clientForm.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Contrato enviado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao enviar o contrato',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      });
  }

  private closeDialogConfirm() {
    this.showDialogConfirm = false;
  }

  private async getStatus() {
    if (!this.clientForm.company_id) return;
    const { id } = this.$route.params;
    const status = id && this.clientForm.status.type ? this.clientForm.status.type : '';
    const data: Status[] = await clientService.getOrderStatus(status, this.clientForm.company_id);

    this.status = data
      .sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
      .map(this.validateStatus);
    this.getSubstatusByStatusItem();
  }

  private validateStatus(status: Status) {
    if (status.type === StatusEnum.CONTRACT && !this.clientForm.proposalFile) {
      status.disabled = true;
      status.description = `${status.description} - Você precisa enviar a proposta.`;
      return status;
    }
    status.disabled = false;
    return status;
  }

  private async getSubstatusByStatusItem() {
    if (!this.clientForm.company_id) return;
    this.substatus = await substatusService.getSubstatusByStatusItem(
      this.clientForm.status.id,
      this.clientForm.company_id,
    );
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));
    this.getEnterprises();
  }

  private setCompanyId(id: string) {
    this.clientForm.company_id = id;
    this.clientForm.enterprise_id = '';
    this.clientForm.unit.id = '';
    this.getSellingPoints();
    this.getStatus();
  }

  private async getSellingPoints() {
    if (!this.clientForm.company_id) return;
    const sellingPoints = await sellingPointService.getSellingPointByCompanyId(
      this.clientForm.company_id,
    );
    this.sellingPoints = sellingPoints.map((it: SellingPoint) => new SellingPoint(it));
    if (this.sellingPoints.length === 1) {
      this.clientForm.selling_point_id = this.sellingPoints[0].id;
    }
  }

  createContactSchedule(reminder: ClientReminder) {
    reminder.type = ReminderType.CALL;
    reminder.to = ReminderTo.ME;
    reminder
      .create(this.clientForm.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Agendamento feito com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao agendar contato',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogConfirm();
      });
  }

  private setStatus(status: Status) {
    this.clientForm.status = status;
    this.clientForm.sub_statuses = '';
    this.getSubstatusByStatusItem();
  }

  created() {
    this.getStatus();
    this.getEnterpriseBlocks();
    this.getCompanies();
    this.getSellingPoints();
  }
}
