
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Reason } from '@/modules/Administrative/modules/Reasons/entities/reason.entity';
import reasonService from '@/modules/Administrative/modules/Reasons/services/reason.service';
import UploadComponent from '@/components/upload/upload.component.vue';
import rulesService from '@/services/rules.service';
import { ClientForm } from '../../entities/client-form.entity';
import { ClientDistract } from '../../entities/client-distract.entity';
import { StatusEnum } from '../../enums/status.enum';

@Component({
  components: {
    UploadComponent,
  },
})
export default class ClientDistractDialog extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private reasons: Reason[] = [];

  private clientDistract: ClientDistract = new ClientDistract();

  private rules = {
    required: rulesService.required,
  };

  private closeDialog(data: boolean = false) {
    if (data) return;
    this.$emit('close');
  }

  private cancellation() {
    if (!this.$refs.form.validate()) return;
    const { id } = this.$route.params;
    if (!this.$refs.form.validate() || !this.$refs.uploadComponent.validate()) return;
    this.clientDistract
      .save(id)
      .then(() => {
        this.$snackbar.open({
          text: 'Distrato gerado com sucesso',
          color: 'success',
          buttonColor: 'white',
        });
        this.$emit('send-distract');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao gerar o distrato',
          color: 'dange',
          buttonColor: 'white',
        });
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  private addDistract() {
    const { id } = this.$route.params;
    this.clientDistract.setDistract(id);
  }

  private async getReasons() {
    const reasons: any[] = await reasonService.getReasonByType(
      this.clientForm.company_id,
      StatusEnum.DISTRACT,
    );
    this.reasons = reasons.map((it: any) => new Reason(it));
  }

  private created() {
    this.getReasons();
  }
}
