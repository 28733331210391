import { Utils } from '@/services/utils/utils.service';
import moment from '@/services/moment/moment.service';

export class ClientChildren {
  public id: string;

  public name: string;

  public birth_date: string;

  constructor(data: any = {}) {
    this.id = Utils.generateID();
    this.name = data.name || '';
    this.birth_date = data.birth_date || moment().format('YYYY-MM-DD');
  }

  public get birthDateFormatted() {
    return moment(this.birth_date).format('DD/MM/YYYY');
  }
}
