
import { mapGetters } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import enterpriseBlocksService from '@/modules/Administrative/modules/Enterprises/components/form/services/enterprise-blocks.service';
import { Block } from '@/modules/Administrative/modules/Enterprises/components/form/entities/block.entity';
import rulesService from '@/services/rules.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import { Reserve } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/reserve.entity';
import ClientPaymentConditionComponent from '../form/components/payment-conditions/client-payment-condition.component.vue';
import { ClientForm } from '../form/entities/client-form.entity';
import simulationProposalService from './services/simulation-proposal.service';
import { ClientPaymentCondition } from '../form/entities/client-payment-condition.entity';

@Component({
  computed: { ...mapGetters(['user']) },
  components: {
    ClientPaymentConditionComponent,
    AlertError,
  },
})
export default class SimulationProposalFormComponent extends Vue {
  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public client!: ClientForm;

  public company_id: string = '';

  public enterprise_id: string = '';

  public user!: any;

  public blockId: string = '';

  public notes: string = '';

  public rules = {
    required: rulesService.required,
  };

  public enterprises: Enterprise[] = [];

  public companies: Company[] = [];

  public blocks: Block[] = [];

  public units: Unit[] = [];

  private errors: any = {};

  public unit: Unit = new Unit({ reserve: new Reserve({ id: '1' }) });

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  public get enterprisesSorted() {
    return this.sortArrayByTextName(this.enterprises);
  }

  public get unitsSorted() {
    return this.sortArrayByOrder(this.units);
  }

  public paymentConditionContent = new ClientForm();

  private sortArrayByTextName(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  public handlePaymentCondition(paymentCondition: any) {
    this.paymentConditionContent.handlePaymentCondition(paymentCondition);
  }

  private sortArrayByOrder(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.order_number < b.order_number) {
        return -1;
      }
      if (a.order_number > b.order_number) {
        return 1;
      }
      return 0;
    });
  }

  public setEnterpriseId(id: string) {
    this.enterprise_id = id;
    this.unit.id = '';
    this.getBlocks();
  }

  public setUnitId(id: string) {
    this.unit.id = id;
    this.paymentConditionContent = new ClientForm({
      id: this.client.id,
      unit: this.unit,
      enterprise: { id: this.enterprise_id },
    });
  }

  private async getEnterprises() {
    if (!this.company_id) return;
    const active = true;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.company_id, active);
    this.enterprises = enterprises.map((it: Enterprise) => new Enterprise(it));
    this.getBlocks();
  }

  public async getBlocks() {
    this.blocks = await enterpriseBlocksService.getBlocks(this.enterprise_id);
  }

  public setBlockId(enterpriseId: string) {
    this.blockId = enterpriseId;
    this.units = [];
    this.getUnits();
  }

  private validateReserveId(unit: Unit) {
    if (!unit.can_do_reserve || (unit.reserve && unit.reserve.id)) {
      return {
        ...unit,
        name: `${unit.name} - indisponível para reserva`,
        disabled: true,
      };
    }
    return unit;
  }

  private validateUnitValue(unit: Unit) {
    if (!unit.sale_value) {
      return {
        ...unit,
        name: `${unit.name} - (Sem valor definido)`,
        disabled: true,
      };
    }
    return unit;
  }

  private async getUnits() {
    if (!this.enterprise_id) return;
    const { data } = await enterpriseBlocksService.getUnitsByBlockId(this.blockId);
    this.units = data
      .map((it: Unit) => new Unit(it))
      .map(this.validateReserveId)
      .map(this.validateUnitValue);
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));

    this.getEnterprises();
  }

  public createProposalSimulation() {
    this.errors = {};
    const form = {
      order_id: this.client.id,
      unit_id: this.unit.id,
      notes: this.notes,
      payment_conditions: this.paymentConditionContent.payment_conditions.map((it: any) => ({
        ...it,
        payment_condition_id: it.id,
      })),
    };

    simulationProposalService
      .createSimulationProposal(form)
      .then(({ url }) => {
        this.$snackbar.open({
          text: 'Simulação criada com sucesso!',
          color: 'success',
          buttonColor: 'white',
        });
        window.open(url, '__blank');
      })
      .catch((err: any) => {
        this.errors = this.getErrorsOnRequest(err);
        this.$snackbar.open({
          text: 'Erro ao gerar simulação!',
          color: 'danger',
          buttonColor: 'white',
        });
        this.scrollTop();
        throw new Error(err);
      });
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private getErrorsOnRequest(err: any) {
    if (err.response && err.response.data.errors) {
      return err.response.data.errors;
    }
    if (err.response && err.response.data.message) {
      return {
        'Ocorreu um erro': [err.response.data.message],
      };
    }
    return {
      'Erro Desconhecido': ['Ocorreu um erro eo enviar a proposta'],
    };
  }

  public created() {
    this.company_id = this.user.company_id || this.client.company_id;
    if (!this.company_id) {
      this.getCompanies();
      return;
    }
    this.getEnterprises();
  }
}
