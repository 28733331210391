
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import TimePicker from '@/components/date-picker/time-picker.component.vue';
import rulesService from '@/services/rules.service';

@Component({
  components: {
    DatePicker,
    TimePicker,
  },
})
export default class VisitScheduleConfirmComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly order_id!: string;

  confirmVisitSchedule = {
    day: '',
    hour: '',
  };

  public rules = {
    required: rulesService.required,
  };

  private close() {
    this.$emit('input', false);
  }

  private setDay(date: string) {
    this.confirmVisitSchedule.day = date;
    this.confirmVisitSchedule.hour = '';
  }

  confirm() {
    this.$emit('confirm', this.confirmVisitSchedule);
  }
}
