
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RadioButtonCustomComponent extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  private readonly radioItem!: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  private checkItem() {
    this.$emit('check-item', this.radioItem);
  }
}
