
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../entities/client-form.entity';
import ClientFormContractDialog from './client-form-contract-dialog.vue';

@Component({
  components: {
    ClientFormContractDialog,
  },
})
export default class ClientFormContract extends Vue {
  @Prop({
    type: Object,
    default: () => new ClientForm(),
  })
  public readonly clientForm!: ClientForm;

  private show = 0;

  private sendContract() {
    this.$emit('send-contract');
  }
}
