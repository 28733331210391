import orderService from '@/modules/Administrative/modules/Orders/services/order.service';
import moment from '@/services/moment/moment.service';

export class UnitReserve {
  public order_id: string;

  public enterprise_id: string;

  public unit_id: string;

  public expires_at: string;

  public notes: string;

  constructor(data: any = {}) {
    this.order_id = data.order_id || '';
    this.enterprise_id = data.enterprise_id || '';
    this.unit_id = data.unit_id || '';
    this.expires_at = data.expires_at
      || moment()
        .add(1, 'day')
        .format('YYYY-MM-DD');
    this.notes = data.notes || '';
  }

  private format() {
    return {
      ...this,
      expires_at: `${this.expires_at} 23:00:00`,
    };
  }

  public reserve() {
    return orderService.reserveUnit(this.format());
  }

  public deleteReserve(payload: any) {
    return orderService.deleteReservedUnit(payload);
  }
}
