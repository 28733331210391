class HandleImage {
  public convertImageToBase64(url: string) {
    return new Promise((resolve: any) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function convert() {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: any = canvas.getContext('2d');
        ctx.drawImage(this, 0, 0);
        const dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
      img.src = url;
    });
  }

  public formatBase64IntoFile(base64: string) {
    const url = base64;
    return fetch(url)
      .then(res => res.blob())
      .then((blob: any) => {
        const file = new File([blob], 'Arquivo');
        return file;
      });
  }

  public convertFileToBase64(file: File): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      const fileData = file instanceof File ? file : await this.formatBase64IntoFile(file);
      reader.readAsDataURL(fileData);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = error => reject(error);
    });
  }
}

export default new HandleImage();
