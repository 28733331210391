
import { Component, Vue, Prop } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import { ClientInformContact } from './entities/client-inform-contact.entity';
import getContactTypesService from './services/get-contact-types.service';
import reminderService from '../../../reminders/service/reminder.service';
import { ClientForm } from '../../entities/client-form.entity';

@Component({ components: { DatePicker } })
export default class ScheduleInformContactDialog extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  public reminder: ClientInformContact = new ClientInformContact();

  public rules = {
    required: rulesService.required,
  };

  public sending: boolean = false;

  public contactTypes: any[] = [];

  public scheduleCalls: any[] = [];

  public confirm() {
    if (!this.$refs.form.validate()) return;
    this.$emit('inform-contact-schedule', this.reminder);
    // this.close();
  }

  public async getContactTypes() {
    const contactTypes: any = await getContactTypesService.getContactTypes();
    this.contactTypes = Object.keys(contactTypes).map((it: string) => ({
      id: it,
      description: contactTypes[it],
    }));
  }

  public async getSchedulesCall() {
    this.scheduleCalls = await reminderService.getRemindersCall(this.clientForm.id);
  }

  public close() {
    this.$emit('close');
  }

  public setDay(date: string) {
    this.reminder.created_at = date;
  }

  created() {
    this.getSchedulesCall();
    this.getContactTypes();
  }
}
