import moment from '@/services/moment/moment.service';

export class SimulationProposalList {
  public id: string;

  public name: string;

  public path: string;

  public createdAt: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.path = data.path;
    this.createdAt = data.created_at;
  }

  get createdAtFormatted(): string {
    return moment(this.createdAt).format('DD/MM/YYYY');
  }

  openInNewTab() {
    window.open(this.path, '__blank');
  }
}
