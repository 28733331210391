
import { Component, Vue, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash';
import moment from '@/services/moment/moment.service';

import DatePicker from '@/components/date-picker/date-picker.component.vue';
import orderService from '@/modules/Administrative/modules/Orders/services/order.service';
import { ClientPaymentCondition } from '../../entities/client-payment-condition.entity';
import { IPaymentInstallments } from '../../interfaces/payment-installments.interface';
import { IPaymentRecalculate } from '../../interfaces/payment-recalculate.interface';

@Component({
  components: {
    DatePicker,
  },
})
export default class PaymentsInstallmentDialog extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private value!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  private installments!: any[];

  @Prop({
    type: Object,
    default: () => new ClientPaymentCondition(),
  })
  private readonly paymentItem!: ClientPaymentCondition;

  private loading: boolean = false;

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private get showRecalcBtn() {
    return this.paymentItem.installments.length > 1;
  }

  private get currentInstallments() {
    return this.installments.length ? this.sortPaymentsByDate(this.installments) : [];
  }

  private sortPaymentsByDate(installments: IPaymentInstallments[] = []) {
    const data = [...installments];
    return data.sort((a: IPaymentInstallments, b: IPaymentInstallments) => {
      if (moment(a.due_date).isSameOrBefore(b.due_date)) {
        return -1;
      }

      return 1;
    });
  }

  private cancel() {
    this.setShowDialog(false);
  }

  public addItemToEditOnInput = debounce((item: IPaymentInstallments) => {
    this.$emit('set-item-to-edit', item);
  }, 600);

  private recalculateInstallments() {
    this.$emit('recalculate');
  }

  private save() {
    // this.paymentData.installments = [...this.installmentsRest, ...this.installmentsEdited];
    this.$emit('save', this.paymentItem);
    this.setShowDialog(false);
  }

  private setShowDialog(data: boolean) {
    if (data === true) return;
    this.$emit('input', false);
  }
}
