
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ClientFormAction extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly actionsAvailable!: any[];

  private menu: boolean = false;

  private activeAction(action: string) {
    this.$emit('action', action);
  }
}
