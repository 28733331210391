
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import clientService from '@/modules/Clients/services/client.service';
import statusService from '@/modules/Administrative/modules/Status/services/status.service';
import { ClientForm } from '../../entities/client-form.entity';
import checklistService from '../../../../../Administrative/modules/Checklists/services/checklist.service';
import { Checklist } from '../../../../../Administrative/modules/Checklists/entities/checklist.entity';

@Component
export default class ClientFormChecklists extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly value!: string[];

  private show: number = 0;

  private status: Status[] = [];

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private checklistsData: Array<Checklist> = [];

  private filter = {
    company_id: '',
    active: 1,
    status_id: '',
  };

  private async setChecklist(checklist: any) {
    this.$emit('input', checklist);
  }

  private async getChecklist() {
    const checklistsData = await checklistService.getAllChecklists(this.filter);
    this.checklistsData = checklistsData.map((checklist: Checklist) => new Checklist(checklist));
  }

  private isChecked(checklist: Checklist) {
    return this.value.includes(checklist.id);
  }

  private setStatus(status_id: string) {
    this.filter.status_id = status_id;
    this.getChecklist();
  }

  private async getStatus() {
    const data: Status[] = await statusService.getStatusByCompanyId(this.clientForm.company_id);

    this.status = data.sort((a, b) => (a.order_number > b.order_number ? 1 : -1));
  }

  private async created() {
    this.filter = {
      company_id: this.clientForm.company_id,
      active: 1,
      status_id: this.clientForm.status.id,
    };
    await this.getStatus();
    await this.getChecklist();
  }
}
