import giveUpService from '@/modules/Clients/services/give-up-.service';

export class ClientGiveUp {
  public reason_id: string;

  public justification: string;

  constructor(data: any = {}) {
    this.reason_id = data.reason_id || '';
    this.justification = data.justification || '';
  }

  public async save(orderId: string) {
    return giveUpService.save(orderId, this);
  }
}
