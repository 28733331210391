export enum ClientTypeNameEnum {
  PROPONENT = 'Proponente',
  GUARANTOR = 'Fiador',
  SPOUSE = 'Cônjuge',
}

export enum ClientTypeValueEnum {
  PROPONENT = 'PROPONENTE',
  GUARANTOR = 'FIADOR',
  SPOUSE = 'CONJUGE',
}
