export class ClientHouseInfo {
  public rent_own: string = '';

  public rent_currency: number;

  constructor(data: any = {}) {
    this.rent_own = data ? data.rent_own : '';
    this.rent_currency = data ? data.rent_currency : 0;
  }
}
