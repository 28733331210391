
import { Component, Vue, Prop } from 'vue-property-decorator';
import clientService from '../../services/client.service';
import rulesService from '../../../../services/rules.service';

@Component
export default class MaritalStatusComponent extends Vue {
  @Prop({
    type: String,
    default: () => '',
  })
  private readonly value!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly outlined!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly rules!: Array<any>;

  private civilState: any[] = [];

  private setState(state: string) {
    this.$emit('input', state);
  }

  private async getCivilStates() {
    this.civilState = await clientService.getCivilStates();
  }

  private created() {
    this.getCivilStates();
  }
}
