
import { Vue, Prop, Component } from 'vue-property-decorator';

import rulesService from '@/services/rules.service';
import { ClientForm } from '../../entities/client-form.entity';

@Component
export default class ClientFinancingSimulationData extends Vue {
  public $refs: any;

  private options = [
    {
      label: 'Sim',
      name: 1,
    },
    {
      label: 'Não',
      name: 0,
    },
  ];

  private amortizationOptions = [
    {
      label: 'Price',
      name: 'Price',
    },
    {
      label: 'SAC',
      name: 'SAC',
    },
    {
      label: 'Outros',
      name: 'Outros',
    },
  ];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private rules = {
    greaterThanOrEqualZero: rulesService.greaterThanOrEqualZero,
  };

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;
}
