import { debounce } from 'lodash';
import moment from '@/services/moment/moment.service';
import { Utils } from '@/services/utils/utils.service';
import orderService from '@/modules/Administrative/modules/Orders/services/order.service';
import { IPaymentInstallments } from '../interfaces/payment-installments.interface';

export class ClientPaymentCondition {
  public id: string;

  public payment_condition_id: string;

  public description: string;

  public active: boolean;

  public qty: number;

  public first_due_date: string;

  public unit_value: number;

  public unit_rate_value: number;

  public total_value: number;

  public total_rate_value: number;

  public order_id: string;

  public error: string;

  public interest_rate: number;

  public installments: IPaymentInstallments[];

  public loading: boolean;

  constructor(data: any = {}) {
    this.id = data.id;
    this.payment_condition_id = data.payment_condition_id || data.id;
    this.description = data.description;
    this.active = data.active;
    this.qty = data.qty;
    this.order_id = data.order_id;
    this.first_due_date = data.first_due_date
      || moment()
        .add(1, 'months')
        .format('YYYY-MM-DD');
    this.unit_value = data.unit_value;
    this.total_value = data.total_value;
    this.unit_rate_value = data.unit_rate_value;
    this.total_rate_value = data.total_rate_value;
    this.interest_rate = data.interest_rate;
    this.loading = data.loading;
    this.installments = data.installments || [];
    this.error = '';
  }

  public get unitValueFormatted() {
    return this.unit_value ? Utils.formatToCurrency(this.unit_value) : '';
  }

  public get descriptionFormatted() {
    return this.active
      ? this.description
      : `${this.description} - condição inativada mas ainda presente na proposta`;
  }

  public get unitValueRateFormatted() {
    return this.unit_rate_value ? Utils.formatToCurrency(this.unit_rate_value) : '';
  }

  public get totalValueFormatted() {
    return this.total_value ? Utils.formatToCurrency(this.total_value) : '';
  }

  public get totalValueRateFormatted() {
    return this.total_rate_value ? Utils.formatToCurrency(this.total_rate_value) : '';
  }

  public isValid() {
    return this.qty && this.qty > 0 && this.unit_value && Number(this.unit_value) > 0;
  }

  public calculate = debounce(async () => {
    this.error = '';
    if (!Number(this.qty) || !this.total_value || !this.first_due_date) return;
    this.loading = true;
    orderService
      .calculate(this.formatToCalculate())
      .then((response: any) => {
        this.setDataAfterCalculate(response);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.data.message) {
          this.error = err.response.data.message;
          return;
        }
        this.error = 'Ocorreu um erro ao calcular a condição de pagamento';
      })
      .finally(() => {
        this.loading = false;
      });
  }, 1000);

  public formatToCalculate() {
    return {
      payment_condition_id: this.payment_condition_id || this.id,
      qty: this.qty,
      total_value: this.total_value,
      order_id: this.order_id,
      first_due_date: this.first_due_date,
      installments: this.installments,
    };
  }

  public formatToSend() {
    return {
      ...this,
      id: undefined,
    };
  }

  private setDataAfterCalculate(data: any) {
    this.qty = data.qty;
    this.total_rate_value = data.total_rate_value;
    this.total_value = data.total_value;
    this.unit_rate_value = data.unit_rate_value;
    this.unit_value = data.unit_value;
    this.installments = data.installments;
  }
}
