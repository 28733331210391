import http from '@/services/http.service';

class OwnerNotifcation {
  notify(params: notifyParams) {
    return http.post('order/person/validate/notify', params);
  }
}

type notifyParams = {
  cellphone: string;
  company_id: string;
};

export default new OwnerNotifcation();
