import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import moment from '@/services/moment/moment.service';
import visitSchedulesService from '../services/visit-schedules.service';

export class VisitSchedule {
  public order_id: string;

  public unit_id: string;

  public title: string;

  public description: string;

  public to: string;

  public visit_at: string;

  public day: string;

  public hour: string;

  public users: User[];

  constructor(data: any = {}) {
    this.order_id = data.order_id;
    this.unit_id = data.unit_id;
    this.title = data.title;
    this.description = data.description;
    this.to = data.to;
    this.visit_at = data.visit_at || new Date();
    this.day = moment(this.visit_at).format('YYYY-MM-DD');
    this.hour = moment(this.visit_at).format('HH:mm') || moment(moment().toNow()).format('HH:mm');
    this.users = data.users && data.users.length ? data.users.map((it: any) => new User(it)) : [];
  }

  format(order_id: string) {
    return {
      ...this,
      visit_at: this.formatDateToSave(),
      users: this.users.length ? this.users.map((user: User) => user.id) : undefined,
      order_id,
    };
  }

  private formatDateToSave() {
    const day = moment(this.day).format('YYYY-MM-DD');
    return `${day} ${this.hour}:00`;
  }

  create(order_id: string) {
    return visitSchedulesService.createVisitSchedule(this.format(order_id));
  }
}
