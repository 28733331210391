import http from '@/services/http.service';

class GetContactTypesService {
  getContactTypes() {
    // return http.get('/api/order/contact-type').then(({ data }) => data.data);
    return {
      CALL: 'Ligação',
      WHATSAPP: 'Whatsapp',
      EMAIL: 'Email',
      OTHERS: 'Outros',
    };
  }
}

export default new GetContactTypesService();
