
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { ClientComments } from '../../entities/client-comments.entity';
import commentService from '../../../../services/comment.service';
import { User } from '../../../../../Administrative/modules/Users/entities/user.entity';
import userService from '../../../../../Administrative/modules/Users/services/user.service';
import userGroupService from '../../../../../Administrative/modules/Users/services/user-group.service';
import { Group } from '../../../../../Administrative/modules/Users/entities/group.entity';
import { GroupEnum } from '../../../../../Administrative/modules/Users/enum/group.enum';
import rulesService from '../../../../../../services/rules.service';

@Component({ components: { DeleteDialog }, computed: { ...mapGetters(['user']) } })
export default class ClientFormComments extends Vue {
  public $refs: any;

  private currentComment = new ClientComments();

  private comments: ClientComments[] = [];

  private users: User[] = [];

  private user!: any;

  private showDialogDelete = false;

  private rules = {
    required: rulesService.required,
  };

  private itemToDelete: ClientComments = new ClientComments();

  private get orderId() {
    return this.$route.params.id;
  }

  private isOwner(item: ClientComments) {
    return this.user.id === item.created_by.id;
  }

  private save() {
    if (!this.$refs.form.validate()) return;
    if (this.currentComment.id) {
      this.update();
      return;
    }
    this.create();
  }

  private deleteComment(itemToDelete: ClientComments) {
    this.showDialogDelete = true;
    this.itemToDelete = new ClientComments(itemToDelete);
  }

  private scrollToTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private editItem(itemToEdit: ClientComments) {
    this.currentComment = new ClientComments(itemToEdit);
    this.scrollToTop();
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private create() {
    this.currentComment
      .create(this.orderId)
      .then(() => {
        this.$snackbar.open({
          text: 'Comentário adicionado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAllComments();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar o comentário',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.currentComment = new ClientComments();
      });
  }

  private update() {
    this.currentComment
      .update()
      .then(() => {
        this.$snackbar.open({
          text: 'Comentário alterado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAllComments();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar o comentário',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.currentComment = new ClientComments();
      });
  }

  private deleteItem() {
    this.itemToDelete
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Comentário deletado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.getAllComments();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao apagar o comentário',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async getAllComments() {
    const { id } = this.$route.params;
    const comments: any[] = await commentService.getAllByClientId(id);

    this.comments = comments.map((it: any) => new ClientComments(it));
  }

  private async getGroupIdPartner() {
    const groupIds: Group[] = await userGroupService.getGroups();
    const partner = groupIds.find((it: Group) => it.name === GroupEnum.PARTNER);
    return partner ? partner.id : '';
  }

  private replyComment(item: ClientComments) {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    const userId: any = this.findUserById(item.created_by.id);
    this.currentComment.users = [userId];
    this.currentComment.message = `RES: ${item.created_by.name}\n -`;
  }

  private findUserById(userId: string) {
    return this.users.find(it => it.id === userId);
  }

  private async getUsers() {
    const groupId = await this.getGroupIdPartner();
    this.users = await userService.getAllUsers({
      params: {
        group_id: groupId,
      },
    });
  }

  private created() {
    this.getAllComments();
    this.getUsers();
  }
}
