
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../entities/client-form.entity';
import clientService from '../../../../services/client.service';
import productInterestService from '../../../../../Administrative/modules/ProductInterests/services/product-interest.service';
import { ProductInterest } from '../../../../../Administrative/modules/ProductInterests/entities/product-interest.entity';

@Component
export default class ClientFormProducts extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly value!: string[];

  private show: number = 0;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private productsList: Array<ProductInterest> = [];

  private async setProduct(product: any) {
    this.$emit('input', product);
  }

  private async getProductInterest() {
    const productsList = await productInterestService.getAllProductInterests({
      params: {
        company_id: this.clientForm.company_id,
        active: 1,
      },
    });
    this.productsList = productsList.map(
      (product: ProductInterest) => new ProductInterest(product),
    );
  }

  private isChecked(product: ProductInterest) {
    return this.value.includes(product.id);
  }

  private created() {
    this.getProductInterest();
  }
}
