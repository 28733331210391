
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClientForm } from '../../entities/client-form.entity';
import downloadService from '../../../../../../services/download.service';

@Component
export default class ClientFormNotification extends Vue {
  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  private show = 0;

  private get items() {
    return this.clientForm.getProcessRealized();
  }

  private actionsByType(type: string) {
    const actions: any = {
      PROPOSAL: this.downloadProposal,
      UNIT_RESERVED: this.goToUnit,
      CONTRACT_SENDED: this.downloadContract,
      DISTRACT_SENDED: this.downloadDistract,
    };
    actions[type]();
  }

  private downloadContract() {
    downloadService.openInNewTab(this.clientForm.contractFilePdf);
  }

  private downloadProposal() {
    downloadService.openInNewTab(this.clientForm.proposalFile);
  }

  private downloadDistract() {
    downloadService.openInNewTab(this.clientForm.distractFile);
  }

  private goToUnit() {
    const routeData = this.$router.resolve({
      name: 'unit-edit',
      params: {
        enterprise_id: this.clientForm.enterprise_id,
        id: this.clientForm.unit.id,
      },
    });
    window.open(routeData.href, '_blank');
  }
}
