import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import moment from '@/services/moment/moment.service';
import store from '@/store';
import commentService from '@/modules/Clients/services/comment.service';

export class ClientComments {
  public id: string = '';

  public message: string = '';

  public users: User[] = [];

  public created_by: any = {};

  public updated_by: string = '';

  public created_at: string = '';

  public updated_at: string = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.message = data.message || '';
    this.users = data.users ? data.users.map((user: User) => new User(user)) : [];
    this.created_by = data.created_by ? data.created_by : {};
    this.created_at = data.created_at || moment().format('DD/MM/YYYY HH:mm:ss');
    this.updated_by = data.updated_by ? data.updated_by.name : '';
    this.updated_at = data.updated_at || '';
  }

  public get dateUpdated() {
    if (this.updated_at) {
      return moment(this.updated_at).format('DD/MM/YYYY HH:mm:ss');
    }
    return moment(this.created_at).format('DD/MM/YYYY HH:mm:ss');
  }

  public get createdDateFormatted() {
    return moment(this.created_at).format('DD/MM/YYYY HH:mm:ss');
  }

  private format() {
    return {
      ...this,
      users: this.users.map((user: User) => user.id),
    };
  }

  public get lastUser() {
    return this.updated_by ? this.updated_by : this.created_by;
  }

  public get isUpdated() {
    return !!this.updated_by;
  }

  public create(order_id: string) {
    return commentService.create({ ...this.format(), order_id });
  }

  public update() {
    return commentService.update(this.format());
  }

  public delete() {
    return commentService.delete(this.id);
  }
}
