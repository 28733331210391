
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import unitsService from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/services/units.service';
import { Block } from '@/modules/Administrative/modules/Enterprises/components/form/entities/block.entity';
import enterpriseBlocksService from '@/modules/Administrative/modules/Enterprises/components/form/services/enterprise-blocks.service';
import { Reason } from '../../../../../Administrative/modules/Reasons/entities/reason.entity';
import reasonService from '../../../../../Administrative/modules/Reasons/services/reason.service';
import rulesService from '../../../../../../services/rules.service';
import { ClientForm } from '../../entities/client-form.entity';
import UploadComponent from '../../../../../../components/upload/upload.component.vue';
import { ClientDistract } from '../../entities/client-distract.entity';
import { StatusEnum } from '../../enums/status.enum';
import enterpriseService from '../../../../../Administrative/modules/Enterprises/services/enterprise.service';
import { Company } from '../../../../../Administrative/modules/Companies/entities/company.entity';
import { Enterprise } from '../../../../../Administrative/modules/Enterprises/entities/enterprise.entity';

import { UnitReserve } from '../../entities/unit-reserve';
import moment from '../../../../../../services/moment/moment.service';
import DatePicker from '../../../../../../components/date-picker/date-picker.component.vue';

@Component({
  components: {
    UploadComponent,
    DatePicker,
  },
})
export default class ClientReserveDialog extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => new ClientForm() })
  private readonly clientForm!: ClientForm;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private showDialog: boolean = false;

  private blocks: Block[] = [];

  private error: string = '';

  private blockId: string = '';

  private companies: Company[] = [];

  private enterprises: Enterprise[] = [];

  private units: Unit[] = [];

  private unitReserve = new UnitReserve();

  private get rulesToEnterprise() {
    return this.clientForm.status.type === StatusEnum.PROPOSAL ? [this.rules.required] : [];
  }

  private rules = {
    required: rulesService.required,
  };

  private sortArrayByTextName(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  private sortArrayByOrder(data: any[] = []) {
    return data.sort((a: any, b: any) => {
      if (a.order_number < b.order_number) {
        return -1;
      }
      if (a.order_number > b.order_number) {
        return 1;
      }
      return 0;
    });
  }

  private get rulesToDeleteReserve() {
    return (
      this.clientForm.status.type === StatusEnum.INITIAL_CONTACT
      || this.clientForm.status.type === StatusEnum.PROPOSAL
    );
  }

  private get enterprisesSorted() {
    return this.sortArrayByTextName(this.enterprises);
  }

  private get unitsSorted() {
    return this.sortArrayByOrder(this.units);
  }

  private get hasReserve() {
    return this.clientForm.unit.reserve && this.clientForm.unit.reserve.id;
  }

  private get buttonLabel() {
    return this.hasReserve ? 'Excluir reserva da unidade' : 'Reservar unidade';
  }

  private get buttonColor() {
    return this.hasReserve ? 'danger' : 'success';
  }

  private get buttonIcon() {
    return this.hasReserve ? 'delete' : 'apartment';
  }

  private close() {
    this.showDialog = false;
  }

  private openDialog(event: any) {
    if (this.hasReserve) {
      this.deleteReserve();
      return;
    }
    this.showDialog = true;
  }

  public allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  private save() {
    this.error = '';
    if (!this.hasReserve) {
      this.reserveUnit();
      return;
    }
    this.deleteReserve();
  }

  private deleteReserve() {
    const payload = {
      order_id: this.clientForm.id,
      unit_id: this.clientForm.unit.id,
    };
    this.unitReserve
      .deleteReserve(payload)
      .then(() => {
        this.$snackbar.open({
          text: 'Reserva removida com sucesso',
          color: 'success',
          buttonColor: 'white',
        });
        this.showDialog = false;
        this.removeUnitAndEnterpriseData();
        this.$emit('send-reserve');
      })
      .catch((err: any) => {
        if (err && err.response.data.message) {
          this.error = err.response.data.message;
        }
        this.$snackbar.open({
          text: 'Erro ao remover a reserva',
          color: 'danger',
          buttonColor: 'white',
        });
      });
  }

  private removeUnitAndEnterpriseData() {
    this.clientForm.enterprise_id = '';
    this.clientForm.unit.id = '';
  }

  private reserveUnit() {
    if (!this.$refs.form.validate()) return;
    this.unitReserve.order_id = this.clientForm.id;
    this.unitReserve
      .reserve()
      .then(() => {
        this.$snackbar.open({
          text: 'Reserva realizada com sucesso',
          color: 'success',
          buttonColor: 'white',
        });
        this.showDialog = false;
        this.$emit('send-reserve');
      })
      .catch((err: any) => {
        if (err && err.response.data.message) {
          this.error = err.response.data.message;
        }
        this.$snackbar.open({
          text: 'Erro ao realizar a reserva',
          color: 'danger',
          buttonColor: 'white',
        });
      });
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));

    this.getEnterprises();
  }

  private async getEnterprises() {
    if (!this.clientForm.company_id) return;
    const active = true;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(
      this.clientForm.company_id,
      active,
    );
    this.enterprises = enterprises.map((it: Enterprise) => new Enterprise(it));
    this.getBlocks();
  }

  public async getBlocks() {
    this.blocks = await enterpriseBlocksService.getBlocks(this.clientForm.enterprise_id);
  }

  private validateReserveId(unit: Unit) {
    if (!unit.can_do_reserve || (unit.reserve && unit.reserve.id)) {
      return {
        ...unit,
        name: `${unit.name} - indisponível para reserva`,
        disabled: true,
      };
    }
    return unit;
  }

  private validateUnitValue(unit: Unit) {
    if (!unit.sale_value) {
      return {
        ...unit,
        name: `${unit.name} - (Sem valor definido)`,
        disabled: true,
      };
    }
    return unit;
  }

  private async getUnits() {
    if (!this.clientForm.enterprise_id) return;
    const { data } = await enterpriseBlocksService.getUnitsByBlockId(this.blockId);
    this.units = data
      .map((it: Unit) => new Unit(it))
      .map(this.validateReserveId)
      .map(this.validateUnitValue);
  }

  private setCompanyId(id: string) {
    this.clientForm.company_id = id;
    this.clientForm.enterprise_id = '';
    this.clientForm.unit.id = '';

    this.getEnterprises();
  }

  private setEnterpriseId(id: string) {
    this.clientForm.enterprise_id = id;
    this.clientForm.unit.id = '';
    this.getBlocks();
  }

  private setUnitId(id: string) {
    this.clientForm.unit.id = id;
  }

  private setBlockId(enterpriseId: string) {
    this.blockId = enterpriseId;
    this.units = [];
    this.getUnits();
  }

  private created() {
    this.getCompanies();
  }
}
