
import { Component, Vue, Prop } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import moment from '@/services/moment/moment.service';
import TimePicker from '@/components/date-picker/time-picker.component.vue';
import { ClientReminder } from '../../../reminders/entities/client-reminders.entity';
import { ReminderType } from '../../../reminders/enums/reminder.enum';

@Component({ components: { DatePicker, TimePicker } })
export default class ScheduleContactDialog extends Vue {
  public $refs: any;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private reminder: ClientReminder = new ClientReminder();

  private rules = {
    required: rulesService.required,
    isValidHour: this.isValidHour,
  };

  private sending: boolean = false;

  private isValidHour(hour: string) {
    if (!this.reminder.day) return '';

    return moment(`${this.reminder.day} ${hour}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  public allowedHours(hour: string) {
    const day = moment(this.reminder.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const minutes = moment(this.reminder.hour, 'HH:mm').format('mm');

    return moment(`${day} ${hour}`, 'YYYY-MM-DD HH').isSameOrAfter(
      moment().format('YYYY-MM-DD HH'),
    );
  }

  public allowedMinutes(minutes: string) {
    const today = moment().format('YYYY-MM-DD');
    const day = moment(this.reminder.day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const hour = this.reminder.hour ? moment(this.reminder.hour, 'HH:mm').format('HH') : '00';
    const isHourValid = this.allowedHours(hour);
    if (!isHourValid) {
      return true;
    }

    return moment(`${day} ${hour}:${minutes}`, 'YYYY-MM-DD HH:mm').isSameOrAfter(
      moment().format('YYYY-MM-DD HH:mm'),
    );
  }

  public allowedDays(value: string) {
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
  }

  private confirm() {
    if (!this.$refs.form.validate()) return;
    this.sending = true;
    this.$emit('create-contact-schedule', this.reminder);
    this.close();
  }

  private close() {
    this.$emit('close');
  }

  private setDay(date: string) {
    this.reminder.day = date;
    this.reminder.hour = '';
  }

  private get typeFormTitle() {
    return this.reminder.id ? 'Editar' : 'Criar um';
  }

  private get typeFormLabelButton() {
    return this.reminder.id ? 'Editar' : 'Criar';
  }
}
