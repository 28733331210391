import http from '@/services/http.service';

class CommentService {
  getAllByClientId(order_id: string) {
    return http
      .get('order-comment', {
        params: {
          order_id,
        },
      })
      .then(({ data }) => data.data);
  }

  create(comment: any) {
    return http.post('order-comment', comment);
  }

  update(comment: any) {
    return http.put(`order-comment/${comment.id}`, comment);
  }

  delete(commentId: string) {
    return http.delete(`order-comment/${commentId}`);
  }
}

export default new CommentService();
